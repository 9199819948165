// @ts-nocheck

import React, { useState } from "react";

import TrashIcon from "../../assets/icons/trash.svg";
import IconButton from "../../components/Buttons/IconButton";
import Input from "../../components/Form/Input";
import { Dialog, DialogBody } from "@material-tailwind/react";
import { toast } from "react-toastify";

const SettingFinancial = ({ configs, onSave, onBack = () => { } }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [depositFee, setDepositFee] = useState(configs?.depositFee ?? 0.1);
    const [withdrawalFee, setWithdrawalFee] = useState(configs?.withdrawalFee ?? 0.1);
    const [currencies, setCurrencies] = useState(configs?.currencies ?? ["USD", "EUR"]);
    const [newCurrencyName, setNewCurrencyName] = useState("");

    const handleSave = () => {
        onSave({ depositFee, withdrawalFee, currencies });
    }

    const handleAddCurrency = () => {
        if (newCurrencyName.length === 0) {
            return toast.error("Please input the currency name!");
        }
        if (currencies.includes(newCurrencyName)) {
            return toast.error("Currency is already exist!");
        }
        setCurrencies([...currencies, newCurrencyName]);
        setIsModalVisible(false);
    }

    const handleRemoveCurrency = (index) => {
        if (currencies.length === 1) {
            return toast.error("At least one currency is required!");
        }

        let newCurrencies = [...currencies];
        newCurrencies.splice(index, 1);
        setCurrencies(newCurrencies);
    }

    return (
        <>
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex justify-between items-center pb-[20px] border-b border-border2">
                    <div>
                        <div className="text-black text-[20px] font-Urbanist-Bold">
                            Financial settings
                        </div>
                        <div className="text-gray6 text-[12px] font-[600]">
                            Adjust your settings
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col items-start gap-5 bg-white rounded-[8px] p-5 md:p-0 md:pt-5 mt-5 md:mt-0">
                    <p className="text-gray6">Manage Currencies</p>
                    <div className="w-full flex flex-col items-start gap-3">
                        {currencies.map((item, index) => (
                            <Input key={index} rightIcon={TrashIcon} className="!bg-[#F3F3F3]"
                                value={item} setValue={() => { }}
                                rightIconClick={() => { handleRemoveCurrency(index) }}
                                readOnly
                            />
                        ))}
                        <div
                            className="text-[#48B825] text-[12px] font-[600] pl-3 cursor-pointer"
                            onClick={() => {
                                setNewCurrencyName("");
                                setIsModalVisible(true);
                            }}
                        >
                            <span className="text-[16px] me-2">+</span><span>Add New Currency</span>
                        </div>
                    </div>
                    <p className="text-gray6">Transaction Fees</p>
                    <div className="w-full">
                        <Input label="Invest Fee (%)" type="number" value={depositFee} setValue={setDepositFee} />
                    </div>
                    <div className="w-full">
                        <Input label="Withdrawal Fee (%)" type="number" value={withdrawalFee} setValue={setWithdrawalFee} />
                    </div>
                    <IconButton text="Save Change" TxSize="text-[14px]" py="py-[10px]" className="w-full md:w-[160px]" onClick={handleSave} />
                    <IconButton text="Back" TxSize="text-[14px]" py="py-[10px]" className="w-full md:hidden" onClick={onBack} />
                </div>
            </div>

            <Dialog open={isModalVisible} handler={() => setIsModalVisible(false)}>
                <DialogBody className="p-8 flex flex-col items-center justify-evenly gap-4 overflow-y-scroll max-h-[calc(100vh-20px)]">
                    <div className="mb-4">
                        <p className="text-black text-[20px] font-[600] text-center">Add New Currency</p>
                        <p className="text-gray6 text-[12px] font-[600]"> </p>
                    </div>
                    <Input label="Currency Name" value={newCurrencyName} setValue={setNewCurrencyName} />

                    <IconButton
                        text="Save Currency" TxSize="text-[14px]" py="py-[10px]" className="w-full mt-4"
                        onClick={handleAddCurrency}
                    />
                </DialogBody>
            </Dialog>
        </>
    );
};

export default SettingFinancial;