// @ts-nocheck

import React, { useState, useEffect } from "react";

import Input from "../../components/Form/Input";
import Textarea from "../../components/Form/Textarea";
import IconButton from "../../components/Buttons/IconButton";
import { Dialog, DialogBody } from "@material-tailwind/react";
import DefaultSelect from "../../components/Form/DefaultSelect";
import DropZone from "../../components/Form/DropZone";
import { DocumentTypes } from "../../utils/contants";
import { hintDocumentNames, validateDocumentName } from "../../utils/validates";
import { toast } from "react-toastify";

const _initData = {
    type: "Contract"
};

const EditDialog = ({ open, onClose, onSubmit, itemInfo, isAdd = false }) => {

    const [values, setValues] = useState(_initData);
    const [nameValid, setNameValid] = useState(false);

    useEffect(() => {
        if (itemInfo) {
            setValues(itemInfo);
            setNameValid(true);
        } else {
            setValues(_initData);
        }
    }, [itemInfo]);

    useEffect(() => {
        if (!open) {
            setValues(itemInfo ? itemInfo : _initData);
        }
    }, [open])

    const handleChange = (name, value) => {
        setValues(prev => ({
            ...values,
            [name]: value
        }));
    }

    const handleAcceptFile = (files) => {
        setValues(prev => ({
            ...values,
            file: files[0],
        }));
    }

    const handleSubmit = () => {
        if (!nameValid) {
            return toast.error("Values are incorrect");
        }
        onSubmit(values);
    }

    return (
        <Dialog open={open} handler={onClose}>
            <DialogBody className="p-8 flex flex-col items-center justify-evenly gap-4 overflow-y-scroll max-h-[calc(100vh-20px)]">
                <div className="mb-4">
                    <p className="text-black text-[20px] font-[600] text-center">
                        {isAdd ? "Add Document" : "Update Document"}
                    </p>
                    <p className="text-gray6 text-[12px] font-[600]">Lorem Ipsum is simply dummy text of the printing and typesetting </p>
                </div>
                <Input
                    label="Document Name"
                    value={values?.name}
                    required={true}
                    setValue={(value) => handleChange("name", value)}
                    checkValid={validateDocumentName}
                    hint={hintDocumentNames}
                    isValid={setNameValid}
                />
                <DefaultSelect
                    label="Document Type"
                    options={DocumentTypes}
                    value={values?.type}
                    setValue={(value) => handleChange("type", value)}
                />
                <DefaultSelect
                    label="Status"
                    options={[
                        { value: 0, label: "Inactive" },
                        { value: 1, label: "Active" },
                    ]}
                    value={values?.status}
                    setValue={(value) => handleChange("status", Number(value))}
                />
                <div className="w-full">
                    <div className="text-black text-[16px] font-[500] mb-3">Upload Document</div>
                    <DropZone
                        kind="documents"
                        maxFiles={1}
                        onAcceptFiles={handleAcceptFile}
                    />
                </div>
                <Input
                    label="Version Number"
                    value={values?.version}
                    setValue={(value) => handleChange("version", value)}
                />
                <Textarea
                    label="Change Log" rows={2}
                    value={values?.description}
                    setValue={(value) => handleChange("description", value)}
                />

                <IconButton
                    text={isAdd ? "Add Document" : "Update Document"} TxSize="text-[14px]" py="py-[10px]" className="w-full mt-4"
                    onClick={handleSubmit}
                />
            </DialogBody>
        </Dialog>
    );
};

export default EditDialog;