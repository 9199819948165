import { useDropzone } from 'react-dropzone'
import UploadIcon from "../../../assets/icons/upload.svg";
import { useEffect, useState } from 'react';
import { apiMultipart } from '../../../services/api';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../loading';
import { getFileUrl } from '../../../utils/utils';

const DropZone = ({
  kind = 'files',
  type = '',
  accept = {},
  maxFiles = 10,
  files = null,
  onAcceptFiles = () => { },
  onDeleteFiles = () => { }
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept,
    maxFiles
  });

  const upload = async () => {
    setIsLoading(true);
    const formData = new FormData();
    acceptedFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    formData.append('kind', kind);
    formData.append('type', type);

    try {
      const res = await apiMultipart.post('/upload', formData);
      if (res?.status === 200) {
        onAcceptFiles(res.data.result);
      } else {
        toast.error("File upload failed!");
      }
    } catch (err) {
      console.log("🚀 ~ upload ~ err:", err)
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      upload();
    }
  }, [acceptedFiles]);

  return (
    <div {...getRootProps()} className="relative w-full border border-dashed border-[#9B9DA5] bg-[#FAFAFA]  flex flex-col justify-center items-center gap-3 p-6 rounded-lg">
      <img src={UploadIcon} className="w-[42px] h-[42px]" alt="upload" />
      <div className="text-[#9B9DA5] text-[14px] font-[400]">
        <div className='text-center'>Drag your file(s) to start uploading</div>
        {files?.map((file, index) => (
          <div key={index} className='text-[#0E0E1E]'>
            <a href={getFileUrl(file.path)} target='_blank' rel="noreferrer">{file.path} ({file.size} bytes)</a>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center gap-3">
        <div className="w-[80px] h-[1px] bg-[#E3E3E8]"></div>
        <div className="text-[12px] text-[#0E0E1E] font-[400]">OR</div>
        <div className="w-[80px] h-[1px] bg-[#E3E3E8]"></div>
      </div>
      <input {...getInputProps()} />
      <div>
        <button
          onClick={open}
          className="text-[12px] text-[#0E0E1E] font-[500] hover:text-white hover:bg-[#48B825] border border-[#DDDDE3] p-[10px_16px] w-[130px] rounded-[6px]">
          Browse
        </button>
        {files?.length > 0 &&
          <button
            onClick={onDeleteFiles}
            className="text-[12px] text-[#0E0E1E] font-[500] hover:text-white hover:bg-[#FF3030] border border-[#DDDDE3] p-[10px_16px] w-[130px] rounded-[6px] ms-2">
            Delete
          </button>
        }
      </div>
      <LoadingSpinner visible={isLoading} global={false} />
    </div>
  );
};

export default DropZone;
