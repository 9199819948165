// @ts-nocheck

import React, { useEffect, useState } from "react";

import Pagination from "../../components/Pagination";
import { getTransactions } from "../../services/axios";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/contants";
import { dollarFormatter, getBlockchainHashLink } from "../../utils/utils";
import { useLocation } from "react-router-dom";


const AllTransactions = () => {

    const location = useLocation();
    const userInfo = location?.state;

    const [dataList, setDataList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        loadPage();
    }, []);

    const loadPage = async (page = 1) => {
        setIsLoading(true);
        const res = await getTransactions({
            page, pageSize, userId: userInfo ? userInfo._id : null
        });
        if (res.status === 200) {
            setDataList(res.data.items);
            setTotalCount(res.data.totalCount);
        }
        setCurPage(page);
        setIsLoading(false);
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        All Transactions
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Car Name</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Date</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Type</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Amount</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Status</p>
                        </div>
                        {dataList.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item.car?.name}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item.kind}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">
                                        {item.nfts?.length > 0 ? `NFT ${item.nfts?.map((nft: any) => `#${nft}`)?.join(",")}` : dollarFormatter(item.amount)}
                                    </p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    {item.status === 1 ?
                                        <a className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer"
                                            href={getBlockchainHashLink(item.txHash)} target="_blank" rel="noreferrer"
                                        >
                                            Completed
                                        </a> :
                                        <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                            Pending
                                        </span>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <Pagination
                        totalCount={totalCount}
                        onGoToPage={(page) => loadPage(page)}
                        pageSize={pageSize}
                    />
                </div>
            </div>
        </div>
    );
};

export default AllTransactions;