const Textarea = ({
  label = "",
  placeholder = "",
  value = "",
  setValue = null,
  rows = 10,
  secondaryMsg = ""
}) => {
  return (
    <div className="flex flex-col gap-2 w-[100%]">
      {label && <p className="text-black text-[16px] font-[500]"> {label} </p>}
      <textarea
        name=""
        id=""
        // cols="30"
        rows={rows}
        className="text-gray5 border border-[#0000001A] rounded-[8px] bg-white outline-none p-4"
        placeholder={placeholder}
        value={value}
        onChange={e => {
          setValue && setValue(e.target.value)
        }}
      ></textarea>
      <span className="text-[12px] text-[#9B9DA5]">{secondaryMsg}</span>
    </div>
  );
};

export default Textarea;
