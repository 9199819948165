// @ts-nocheck

import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Input from "../../components/Form/Input";
import DefaultSelect from "../../components/Form/DefaultSelect";
import Textarea from "../../components/Form/Textarea";
import IconButton from "../../components/Buttons/IconButton";
import { Checkbox } from "@material-tailwind/react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DateSinglePicker from "../../components/Form/DatePicker";
import ImageDropZone from "../../components/Form/ImageDropZone";
import { addCMSItem, updateCMSItem } from "../../services/axios";
import { toast } from "react-toastify";
import { hintTitle, validateTitle } from "../../utils/validates";
import { convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const PageEdit = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);
    const [pageInfo, setPageInfo] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [titleValid, setTitleValid] = useState(false);


    useEffect(() => {
        if (location.state) {
            setIsEdit(true);
            setPageInfo(location.state);

            const contentBlock = htmlToDraft(location.state.description);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                setEditorState(EditorState.createWithContent(contentState));
            }
        }
    }, [location]);

    const handleEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const handleChange = (name, value) => {
        setPageInfo(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleAcceptFile = (files) => {
        setPageInfo(prev => ({
            ...prev,
            image: files[0],
        }));
    }

    const handleSubmit = async () => {
        if (!titleValid) {
            return toast.error("Values are incorrect!");
        }
        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (description.length === 0) {
            return toast.error("Description is empty!");
        }

        const res = isEdit ? await updateCMSItem(pageInfo._id, 'page', { ...pageInfo, description }) : await addCMSItem('page', { ...pageInfo, description });
        if (res.status === 200) {
            toast.success("Successfully saved!");
            navigate("/cms/page");
        } else {
            toast.error(res.data.message);
        }
    }


    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        {isEdit ? "Edit Page" : "Create Page"}
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                    </div>
                </div>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6 px-[32px] py-[24px]">
                <div className="md:col-span-2 w-full flex flex-col justify-start items-center gap-6">
                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">Create Page</p>
                        </div>
                        <Input
                            label="Page Title"
                            value={pageInfo?.title}
                            secondaryMsg="At least 100 characters. Special characters are not allowed. (e.g. @, /, -, *, #)"
                            setValue={(value) => handleChange("title", value)}
                            required={true}
                            checkValid={validateTitle}
                            hint={hintTitle}
                            isValid={setTitleValid}
                        />
                        <div className="w-full flex flex-col gap-2 relative">
                            <p className="text-black text-[16px] font-[500]">Description</p>
                            <div>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="rounded-[4px]"
                                    wrapperClassName=""
                                    editorClassName="border border-border2 px-2 min-h-[300px] max-h-[600px] rounded-[4px]"
                                    onEditorStateChange={handleEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'textAlign', 'list'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough']
                                        }
                                    }}
                                />
                            </div>
                            <p className="text-[12px] text-[#9B9DA5]">Hover on edit icon to format text.</p>
                        </div>
                    </div>

                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">Featured Image</p>
                        </div>
                        <ImageDropZone
                            kind="cms"
                            maxFiles={1}
                            onAcceptFiles={(files) => handleAcceptFile(files)}
                        />
                    </div>

                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">Excerpt</p>
                        </div>
                        <Textarea
                            rows={5}
                            value={pageInfo?.excerpt}
                            setValue={(value) => handleChange("excerpt", value)}
                        />
                    </div>

                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">Slug</p>
                        </div>
                        <Input
                            value={pageInfo?.slug}
                            setValue={(value) => handleChange("slug", value)}
                        />
                    </div>

                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">Author</p>
                        </div>
                        <Input
                            value={pageInfo?.author}
                            setValue={(value) => handleChange("author", value)}
                        />
                    </div>

                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">SEO</p>
                        </div>
                        <Input
                            label="Page Title"
                            value={pageInfo?.seoTitle}
                            setValue={(value) => handleChange("seoTitle", value)}
                        />
                        <Input
                            label="Meta Keywords"
                            value={pageInfo?.seoKeywords}
                            setValue={(value) => handleChange("seoKeywords", value)}
                        />
                        <Textarea
                            rows={5}
                            label="Meta Descriptions"
                            value={pageInfo?.seoDescription}
                            setValue={(value) => handleChange("seoDescription", value)}
                        />
                    </div>
                </div>

                <div className="w-full flex flex-col justify-start items-center gap-6">
                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">Publish</p>
                        </div>
                        <DefaultSelect
                            label="Status"
                            options={[
                                { value: 0, label: "Pending" },
                                { value: 1, label: "Published" },
                            ]}
                            value={pageInfo?.publishStatus}
                            setValue={(value) => handleChange("publishStatus", value)}
                        />
                        <DefaultSelect
                            label="Visibility"
                            options={[
                                { value: 0, label: "Private" },
                                { value: 1, label: "Public" },
                            ]}
                            value={pageInfo?.visibility}
                            setValue={(value) => handleChange("visibility", value)}
                        />
                        <DateSinglePicker
                            label="Published on"
                            date={pageInfo?.publishedAt}
                            setDate={(value) => handleChange("publishedAt", value)}
                        />
                        <IconButton
                            text="Publish"
                            TxSize="text-[14px]" py="py-[10px]" className="w-full md:w-[120px]"
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageEdit;