
import React, { useState, useEffect } from "react";
import { FaAngleLeft, FaAngleRight, FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const Pagination = ({ totalCount, pageSize = 10, showFirst = false, showLast = false, onGoToPage }) => {

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / pageSize));
    setPage(1);
  }, [totalCount, pageSize]);

  useEffect(() => {
    let _pages = [];
    if (!isNaN(totalPage)) {
      for (let p = -2; p <= 2; p++) {
        if (p + page < 1) continue;
        if (p + page > totalPage) continue;
        _pages.push(p + page);
      }
    }
    if (_pages.length === 0) {
      _pages.push(1);
    }
    setPages(_pages);
  }, [page, totalPage]);

  const gotoPage = (toPage) => {
    if (toPage === page || toPage < 1 || toPage > totalPage) {
      return;
    };
    if (onGoToPage) {
      setPage(toPage);
      onGoToPage(toPage);
    }
  }

  return (
    <div className="w-full flex justify-center sm:justify-end items-center gap-[11.2px] select-none">
      {(showFirst) && <button
        onClick={() => gotoPage(1)}
        className="rounded-[5px] p-1 text-[#7A7E82] hover:text-[#48B825]">
        <FaAnglesLeft className="w-5 h-5 " />
      </button>}
      <button
        onClick={() => gotoPage(page - 1)}
        className="rounded-[5px] p-1 text-[#7A7E82] hover:text-[#48B825]">
        <FaAngleLeft className="w-5 h-5 " />
      </button>
      <div className="bg-white rounded-[52px] py-1 px-5 flex gap-[11.2px] justify-center items-center">
        {
          pages.map(p =>
          (
            <button
              key={p}
              className={`rounded-[5px] min-w-[27px] font-[500] hover:bg-[#48B825] hover:text-white p-[3px_10px] text-[14px] ${p === page ? 'bg-[#48B825] text-white' : 'text-[#7D7E7D]'}`}
              onClick={() => gotoPage(p)}
            >
              <p className="text-center">{p}</p>
            </button>
          )
          )
        }
      </div>
      <button
        onClick={() => gotoPage(page + 1)}
        className="rounded-[5px] p-1 text-[#7A7E82] hover:text-[#48B825]">
        <FaAngleRight className="w-5 h-5 " />
      </button>
      {(showLast) && <button
        onClick={() => gotoPage(totalPage)}
        className="rounded-[5px] p-1 text-[#7A7E82] hover:text-[#48B825]">
        <FaAnglesRight className="w-5 h-5 " />
      </button>}
    </div>
  );
};

export default Pagination;
