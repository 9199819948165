// @ts-nocheck

import React, { useEffect, useState } from "react";

import Input from "../../components/Form/Input";
import { Dialog, DialogBody, Switch } from "@material-tailwind/react";
import IconButton from "../../components/Buttons/IconButton";
import { useAuth } from "../../hooks/useAuth";
import { addOrUpdateAdmin, disableOTP, generateOTP, verifyOTP } from "../../services/axios";
import QRCode from "qrcode";
import { toast } from "react-toastify";
import { showConfirmBox } from "../../utils/utils";

const SettingSecurity = ({ onBack = () => { } }) => {

    const { user, updateUser } = useAuth();

    const [phoneNumber, setPhoneNumber] = useState(user?.recoveryPhone ?? "");
    const [otpEnabled, setOtpEnabled] = useState(user?.otpEnabled);

    const [isSMSModalVisible, setIsSMSModalVisible] = useState(false);
    const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
    const [secret, setSecret] = useState({
        otpAuthUrl: "",
        otpBase32: "",
    });
    const [qrcodeUrl, setQrCodeUrl] = useState("");
    const [verifyCode, setVerifyCode] = useState("");

    useEffect(() => {
        if (secret.otpAuthUrl) {
            QRCode.toDataURL(secret.otpAuthUrl).then(setQrCodeUrl);
        }
    }, [secret]);

    const handleSMSSetup = () => {
        setPhoneNumber(user?.recoveryPhone ?? "");
        setIsSMSModalVisible(true);
    }

    const handleSavePhoneNumber = async () => {
        if (!user) {
            return;
        }
        if (phoneNumber == "") {
            return toast.info("Please input the phone number!");
        }
        const res = await addOrUpdateAdmin({ id: user.id, recoveryPhone: phoneNumber });
        if (res.status === 200) {
            updateUser({
                ...user, 
                recoveryPhone: phoneNumber
            });
            setIsSMSModalVisible(false);
        } else {
            toast.error(res.data.message);
        }
    }

    const handleChangeOTP = (e) => {
        if (!e.target.checked) {
            showConfirmBox("Warning", "Are you sure to disable 2FA?", async () => {
                setOtpEnabled(false);
                await disableOTP({});
            });
        } else {
            setOtpEnabled(true);
        }
    }

    const handleOTPSetup = async () => {
        const res = await generateOTP();
        if (res.status === 200) {
            setSecret({
                otpBase32: res.data.otpBase32,
                otpAuthUrl: res.data.otpAuthUrl,
            });
            setVerifyCode("");
            setIsOTPModalVisible(true);
        } else {
            toast.error(res.data.message);
        }
    }

    const handleVerifyCode = async () => {
        if (!verifyCode) {
            return toast.error("Please input the authentication code");
        }

        const res = await verifyOTP({
            otpBase32: secret.otpBase32,
            otpAuthUrl: secret.otpAuthUrl,
            token: verifyCode 
        });
        if (res.status === 200) {
            setIsOTPModalVisible(false);
            toast.success("Two-Factor Auth Enabled Successfully");
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <>
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex justify-between items-center pb-[20px] border-b border-border2">
                    <div>
                        <div className="text-black text-[20px] font-Urbanist-Bold">
                            Security
                        </div>
                        <div className="text-gray6 text-[12px] font-[600]">
                            Adjust your settings
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col items-start gap-4 bg-white rounded-[8px] p-5 md:p-0 md:pt-5 mt-5 md:mt-0">
                    <div className="w-full flex items-center justify-between border-b pb-4 border-border2">
                        <span className="text-[16px] text-black">Two-Factor Authentication (2FA)</span>
                        <Switch color="green" checked={otpEnabled} onChange={handleChangeOTP} />
                    </div>
                    <div className="w-full flex items-center justify-between border-b pb-4 border-border2">
                        <div>
                            <div className="text-[16px] text-black">SMS Recovery</div>
                            <div className="text-[14px] text-gray6">Your phone Number or something</div>
                        </div>
                        <button
                            className="text-[14px] text-black font-[500] hover:text-white hover:bg-[#48B825] disabled:text-gray disabled:bg-white border border-border2 p-[10px] w-[100px] rounded-[6px]"
                            disabled={!otpEnabled}
                            onClick={handleSMSSetup}
                        >
                            Setup
                        </button>
                    </div>
                    <div className="w-full flex items-center justify-between pb-4">
                        <div>
                            <div className="text-[16px] text-black">Authenticator App</div>
                            <div className="text-[14px] text-gray6">Google Authenticator App</div>
                        </div>
                        <button
                            className="text-[14px] text-black font-[500] hover:text-white hover:bg-[#48B825] disabled:text-gray disabled:bg-white border border-border2 p-[10px] w-[100px] rounded-[6px]"
                            disabled={!otpEnabled}
                            onClick={handleOTPSetup}
                        >
                            Setup
                        </button>
                    </div>
                    <IconButton text="Back" TxSize="text-[14px]" py="py-[10px]" className="w-full md:hidden" onClick={onBack} />
                </div>
            </div>

            <Dialog open={isSMSModalVisible} handler={() => setIsSMSModalVisible(false)}>
                <DialogBody className="p-8 flex flex-col items-center justify-evenly gap-4 overflow-y-scroll max-h-[calc(100vh-20px)]">
                    <div className="mb-4">
                        <p className="text-black text-[20px] font-[600] text-center">SMS Recovery Phone Number</p>
                        <p className="text-gray6 text-[12px] font-[600]"> </p>
                    </div>
                    <Input label="Phone Number" value={phoneNumber} setValue={setPhoneNumber} />

                    <IconButton text="Save" TxSize="text-[14px]" py="py-[10px]" className="w-full mt-4" onClick={handleSavePhoneNumber} />
                </DialogBody>
            </Dialog>

            <Dialog open={isOTPModalVisible}>
                <DialogBody className="p-8 flex flex-col items-center justify-evenly gap-5 overflow-y-scroll max-h-[calc(100vh-20px)]">
                    <div className="mb-2">
                        <p className="text-black text-[20px] font-[600] text-center">Two-Factor Authentication</p>
                    </div>
                    <div className="w-full">
                        <p className="text-gray5 text-[16px] font-[500]">Scan QR Code</p>
                        <div className="flex justify-center">
                            <img
                                className="block w-64 h-64 object-contain"
                                src={qrcodeUrl}
                                alt="qrcode url"
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        <p className="text-gray5 text-[16px] font-[500]">Or Enter Code Into Your App</p>
                        <p className="text-black text-[16px] font-[600] text-center">{secret.otpBase32}</p>
                    </div>
                    <div className="w-full">
                        <p className="text-gray5 text-[16px] font-[500]">Verify Code</p>
                        <Input label="" value={verifyCode} setValue={setVerifyCode} placeholder="6-digits Authentication Code" />
                    </div>

                    <div className="w-full flex flex-row justify-center gap-4">
                        <button
                            className="text-[14px] text-black font-[500] border border-gray5 p-[10px] w-full rounded-[6px]"
                            onClick={() => setIsOTPModalVisible(false)}>
                            Close
                        </button>
                        <IconButton text="Verify & Activate" TxSize="text-[14px]" py="py-[10px]" className="w-full"
                            onClick={handleVerifyCode}
                        />
                    </div>
                </DialogBody>
            </Dialog>
        </>
    );
};

export default SettingSecurity;