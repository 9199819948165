// @ts-nocheck

import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

import { useNavigate } from "react-router-dom";

import ActiveUserIcon from "../../assets/icons/users/active-user.svg";
import SuspendUserIcon from "../../assets/icons/users/suspend-user.svg";
import PendingUserIcon from "../../assets/icons/users/pending-user.svg";

import CSVIcon from "../../assets/icons/csv.svg";
import { CSVLink } from "react-csv";

import Pagination from "../../components/Pagination";
import SearchInput from "../../components/Form/SearchInput";
import { getUsers, getUsersOverview } from "../../services/axios";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/contants";
import IconButton from "../../components/Buttons/IconButton";
import { getFileUrl, getTrimedAddress } from "../../utils/utils";

const _overViewInfo = [
    {
        id: "active_users",
        title: "Active Users",
        value: 0,
        decimals: 0,
        icon:
            <div className="absolute w-[54px] h-[54px] rounded-full bg-[#F3F3F3] top-6 right-6 flex justify-center items-center">
                <img src={ActiveUserIcon} alt="users" />
            </div>,
        isRising: true,
        risingPercent: 0,
        risingValue: 0,
    },
    {
        id: "suspended_users",
        title: "Suspended Users",
        value: 0,
        decimals: 0,
        icon:
            <div className="absolute w-[54px] h-[54px] rounded-full bg-[#F3F3F3] top-6 right-6 flex justify-center items-center">
                <img src={SuspendUserIcon} alt="tx" />
            </div>,
        isRising: true,
        risingPercent: 0,
        risingValue: 0,
    },
    {
        id: "pending_users",
        title: "Pending Verification",
        value: 0,
        decimals: 0,
        icon:
            <div className="absolute w-[54px] h-[54px] rounded-full bg-[#F3F3F3] top-6 right-6 flex justify-center items-center">
                <img src={PendingUserIcon} alt="invest" />
            </div>,
        isRising: true,
        risingPercent: 0,
        risingValue: 0,
    },
]



const Users = () => {

    const navigate = useNavigate();

    const [overviewData, setOverviewData] = useState(_overViewInfo);
    const [dataList, setDataList] = useState([]);
    const [exportDataList, setExportDataList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        loadPage();
        loadOverview();
    }, []);

    const loadOverview = async () => {
        const res = await getUsersOverview();
        if (res.status === 200) {
            let overviewInfo = [..._overViewInfo];
            overviewInfo[0].value = res.data.activeCount;
            overviewInfo[1].value = res.data.suspendCount;
            overviewInfo[2].value = res.data.pendingCount;
            setOverviewData(overviewInfo);
        }
    }

    const loadPage = async (page = 1) => {
        const res = await getUsers({
            page, pageSize, keyword
        });
        if (res.status === 200) {
            setDataList(res.data.items);
            setTotalCount(res.data.totalCount);
        }
        setCurPage(page);
    }

    const handleView = (item) => {
        navigate("/users/profile", {
            state: item
        });
    }

    const handleExport = async (event, done) => {
        const res = await getUsers({
            page: 1, totalCount
        });
        if (res.status === 200) {
            setExportDataList(res.data.items.map(item => ({
                Name: item.fullname,
                Email: item.email,
                Wallet: item.depositAddress,
                Status: (item.status === 0 ? "Pending" : item.status === 1 ? "Active" : "Suspended"),
            })));
            done(true);
        } else {
            done(false);
        }
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        User Management
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                        Manage all the users
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
                    {
                        overviewData.map((item, index) =>
                            <div key={index} className="bg-white h-full p-6 relative select-none rounded-[8px]">
                                <p className="text-[16px] font-[600] text-gray6">{item.title}</p>
                                <p className="text-black text-[29px] font-semibold mt-2">{item.prefix ?? ''} <CountUp end={item.value} separator="," decimals={item.decimals} /></p>
                                {/* <div className="flex items-center mt-6 gap-2">
                                    {item.isRising ?
                                        <img src={UpRightIcon} alt="up" className="w-4 h-4" />
                                        :
                                        <img src={DownLeftIcon} alt="down" className="w-4 h-4" />
                                    }
                                    <label className={`text-[14px] font-semibold ${item.isRising ? "text-[#55BA68]" : "text-[#F86E51]"}`}>{item.risingPercent}%</label>
                                    <label className="text-[14px] font-semibold text-gray6">{item.risingValue} this week</label>
                                </div> */}
                                {item.icon}
                            </div>
                        )
                    }
                </div>

                <div className="w-full flex justify-between">
                    <SearchInput
                        bgClass="bg-white" className='border border-[#d2cbcb]' placeholder="Search Users"
                        value={keyword} setValue={setKeyword} onPressEnter={loadPage}
                    />
                    <div></div>
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600]">List of Users</p>
                        <CSVLink
                            data={exportDataList}
                            filename={"UserList.csv"}
                            asyncOnClick={true}
                            onClick={handleExport}
                            className="flex justify-center items-center gap-2 w-[160px] border border-gray6 text-gray6 text-[14px] hover:bg-[#eeeeee] rounded-md py-[8px]"
                        >
                            <img src={CSVIcon} alt="csv" />
                            Download CSV
                        </CSVLink>
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Name</p>
                            <p className="w-[20%] min-w-[200px] text-gray6 text-[14px] font-[500]">Email</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Wallet</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Status</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Last Login</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                        </div>
                        {dataList.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[20%] min-w-[150px] flex items-center gap-2">
                                    {item.avatar &&
                                        <img src={getFileUrl(item.avatar)} className="w-6 h-6 rounded-full" alt="avatar" />
                                    }
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{item.fullname}</p>
                                </div>
                                <div className="w-[20%] min-w-[200px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{item.email}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden text-ellipsis">{getTrimedAddress(item.depositAddress)}</p>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    {item.status === 1 ?
                                        <span className="block p-[4px_12px] w-[100px] bg-[#55BA6833] rounded-[36px] text-[12px] text-[#55BA68] text-center cursor-pointer">
                                            Active
                                        </span> :
                                        item.status === 2 ?
                                            <span className="block p-[4px_12px] w-[100px] bg-[#F86E5133] rounded-[36px] text-[12px] text-[#F86E51] text-center cursor-pointer">
                                                Suspended
                                            </span> :
                                            <span className="block p-[4px_12px] w-[100px] bg-[#F86E5133] rounded-[36px] text-[12px] text-[#F86E51] text-center cursor-pointer">
                                                Pending
                                            </span>
                                    }
                                </div>
                                <div className="w-[10%] min-w-[100px]">
                                    <p className="text-[14px] font-[500] text-black">
                                        {item.lastLoginAt && dayjs(item.lastLoginAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}
                                    </p>
                                </div>
                                <div className="w-[15%] min-w-[150px] flex justify-end">
                                    <IconButton
                                        text="View Profile" TxSize="text-[12px]" py="py-[4px]" className="w-[100px]"
                                        onClick={() => handleView(item)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <Pagination
                        totalCount={totalCount}
                        onGoToPage={(page) => loadPage(page)}
                        pageSize={pageSize}
                    />
                </div>
            </div>
        </div>
    );
};

export default Users;