
import SearchIcon from "../../../assets/icons/search.svg";

const SearchInput = ({
  value = '',
  placeholder='Search',
  bgClass='bg-[#F3F3F3]',
  className='',
  widthClass='w-[300]',
  setValue = () => {},
  onPressEnter = () => {},
  ...props
}) => {
  
  const handleKeyPress = (e) => {
    if (e.key == "Enter") {
      onPressEnter();
    }
  }

  return (
    <div className={`relative max-sm:w-[100%] ${widthClass}`}>
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        className={`w-[100%] h-[44px] pl-11 py-3 pr-5 rounded-[8px] text-[#86888C] text-[16px] outline-none ${bgClass} ${className}`}
        onKeyUp={handleKeyPress}
        {...props}
      />
      <img
        src={SearchIcon}
        alt="image"
        className="absolute top-3 left-4"
      />
    </div>
  );
};

export default SearchInput;
