// @ts-nocheck

import React, { useEffect, useState } from "react";

import Pagination from "../../components/Pagination";
import { getNfts } from "../../services/axios";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/contants";
import { dollarFormatter, getBlockchainAccountLink, getBlockchainHashLink, getTrimedAddress } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import CSVIcon from "../../assets/icons/csv.svg";


const NFTs = () => {

    const location = useLocation();
    const userInfo = location?.state;

    const [dataList, setDataList] = useState([]);
    const [exportDataList, setExportDataList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [pageSize] = useState(10);

    useEffect(() => {
        loadPage();
    }, []);

    const loadPage = async (page = 1) => {
        setIsLoading(true);
        const res = await getNfts({
            page, pageSize, ownerAddress: userInfo ? userInfo.depositAddress : null
        });
        if (res.status === 200) {
            setDataList(res.data.items);
            setTotalCount(res.data.totalCount);
        }
        setCurPage(page);
        setIsLoading(false);
    }

    const handleExport = async (event, done) => {
        const res = await getNfts({
            page: 1, totalCount
        });
        if (res.status === 200) {
            setExportDataList(res.data.items.map(item => ({
                Name: item.name,
                CreatedAt: dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE),
                OwnerAddress: item.ownerAddress,
                NFTAddress: item.address,
                Amount: item.amount,
            })));
            done(true);
        } else {
            done(false);
        }
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div className="text-black text-[20px] font-Urbanist-Bold">
                    NFT Transactions
                </div>
                <div className="text-gray6 text-[12px] font-[600]">
                    <CSVLink
                        data={exportDataList}
                        filename={"NFTList.csv"}
                        asyncOnClick={true}
                        onClick={handleExport}
                        className="flex justify-center items-center gap-2 w-[160px] border border-gray6 text-gray6 text-[14px] hover:bg-[#eeeeee] rounded-md py-[8px]"
                    >
                        <img src={CSVIcon} alt="csv" />
                        Download CSV
                    </CSVLink>
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">NFT Name</p>
                            <p className="w-[15%] min-w-[100px] text-gray6 text-[14px] font-[500]">Date</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Owner</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Type</p>
                            <p className="w-[15%] min-w-[100px] text-gray6 text-[14px] font-[500]">Amount</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Status</p>
                        </div>
                        {dataList.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item.name}</p>
                                </div>
                                <div className="w-[15%] min-w-[100px]">
                                    <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <a className="text-[14px] font-[500] text-black"
                                        href={getBlockchainAccountLink(item.ownerAddress)} target="_blank" rel="noreferrer"
                                    >
                                        {getTrimedAddress(item.ownerAddress)}
                                    </a>
                                </div>
                                <div className="w-[10%] min-w-[100px]">
                                    <p className="text-[14px] font-[500] text-black">NFT</p>
                                </div>
                                <div className="w-[15%] min-w-[100px]">
                                    <p className="text-[14px] font-[500] text-black">{dollarFormatter(item.amount)} USDC</p>
                                </div>
                                <div className="w-[10%] min-w-[100px]">
                                    <a className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer"
                                        href={getBlockchainAccountLink(item.address)} target="_blank" rel="noreferrer"
                                    >
                                        Generate
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <Pagination
                        totalCount={totalCount}
                        onGoToPage={(page) => loadPage(page)}
                        pageSize={pageSize}
                    />
                </div>
            </div>
        </div>
    );
};

export default NFTs;