// @ts-nocheck

import React, { useEffect, useState } from "react";

import { dollarFormatter, getBlockchainAccountLink, getFileUrl, showConfirmBox } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

import Pagination from "../../components/Pagination";
import IconButton from "../../components/Buttons/IconButton";

import { deleteCar, getCarsList } from "../../services/axios";
import { toast } from "react-toastify";


const Cars = () => {
    const navigate = useNavigate();

    const [dataList, setDataList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [pageSize, setPageSize] = useState(8);

    useEffect(() => {
        loadPage();
    }, []);

    const loadPage = async (page = 1) => {
        const res = await getCarsList({
            page, pageSize
        });
        if (res.status === 200) {
            setDataList(res.data.items);
            setTotalCount(res.data.totalCount);
        }
        setCurPage(page);
    }

    const handleAdd = () => {
        navigate("/cars/edit");
    }

    const handleEdit = (item) => {
        navigate("/cars/edit", {
            state: item
        });
    }

    const handleRemove = (item) => {
        showConfirmBox("Warning", "Are you sure to delete?",
            async () => {
                const res = await deleteCar(item._id);
                if (res.status === 200) {
                    toast.success("Successfully deleted!");
                    loadPage(curPage);
                } else {
                    toast.error(res.data.message);
                }
            });
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Manage Tokenized Cars
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                        Manage all the Tokenized Cars
                    </div>
                </div>
                <IconButton text="Add New Car" TxSize="text-[14px]" py="py-[10px]" className="w-[150px] hidden sm:block" onClick={handleAdd} />
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <IconButton text="Add New Car" TxSize="text-[14px]" py="py-[10px]" className="w-full max-w-[300px] sm:hidden" onClick={handleAdd} />

                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                    {dataList.map((item, index) => (
                        <div key={index} className="flex justify-center">
                            <div className="w-full max-w-[300px] bg-white rounded-xl overflow-hidden flex flex-col relative hover:shadow-xl">
                                <img src={getFileUrl(item.photos?.[0]?.path)} className="w-full h-[180px]" alt="car" />
                                <div className="absolute top-4 left-4 bg-white text-black font-[500] text-[14px] rounded-[33px] p-[6px_14px]">Tokenized</div>
                                <div className="flex flex-col p-4 gap-3">
                                    <div className="text-black5 text-[18px] font-[600]">{item.name}</div>
                                    <div className="text-gray6 text-[11px] font-[400]">{item.address}</div>
                                    <div>
                                        <span className="text-gray6 font-[500]">Amount to funded: </span>
                                        <span className="text-black font-[600]">{dollarFormatter(item.amountFunded)}</span>
                                    </div>
                                    <div className="text-[14px]">
                                        <span className="text-gray6 font-[500]">Vehicle History: </span>
                                        <span className="text-black font-[600]">{item.resaleYear}</span>
                                    </div>
                                    <div className="flex justify-between gap-3">
                                        <button className="text-[14px] text-[#48B825] font-[500] hover:text-white hover:bg-[#48B825] border border-[#48B82552] p-[10px] w-full rounded-[6px]" onClick={() => handleEdit(item)}>Edit</button>
                                        <button className="text-[14px] text-[#FF5C5C] font-[500] hover:text-white hover:bg-[#FF5C5C] border border-[#FF303033] p-[10px] w-full rounded-[6px]" onClick={() => handleRemove(item)}>Remove</button>
                                    </div>
                                    <IconButton text="View the Car" TxSize="text-[14px]" py="py-[10px]" className="w-full"
                                        onClick={() => window.open(getBlockchainAccountLink(item.address), "_blank")}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div>
                    <Pagination
                        totalCount={totalCount}
                        onGoToPage={(page) => loadPage(page)}
                        pageSize={pageSize}
                    />
                </div>
            </div>
        </div>
    );
};

export default Cars;