//@ts-nocheck


import React, { useEffect, useState } from "react";

import MenuIcon from "../assets/image/menu.svg";

import LogoImg from "../assets/image/logo.png"
import Alarms from "../assets/image/alarms.svg";
import Alarms2 from "../assets/image/alarms2.svg";
import Avatar from "../assets/image/avatar.svg";

import SearchInput from "../components/Form/SearchInput";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const Header = (props: any) => {

    const navigate = useNavigate();
    const { user } = useAuth();

    const [userName, setUserName] = useState(user?.fname + " " + user?.lname);
    const [avatarUrl, setAvatarUrl] = useState(user?.avatar);
    const [userRole, setUserRole] = useState(user?.role);

    const [alertCount, setAlertCount] = useState(0);

    useEffect(() => {

    }, [])


    return (
        <>
            <div className="w-full h-[72px] px-10 bg-white flex items-center justify-between">
                <div className="flex justify-between items-center">
                    <div className="items-center gap-x-2 lg:hidden flex" onClick={() => navigate("/")}>
                        <img className="w-[30px] h-[29px]" src={LogoImg} alt="Logo" />
                        <p className="text-black5 text-[18px] font-[800] font-Urbanist-Bold">Hulk Cars</p>
                    </div>
                </div>
                <div className="flex justify-center items-center gap-3">
                    <div className="w-10 h-10 rounded-[20px] bg-[#f3f3f3] items-center justify-center flex cursor-pointer relative" onClick={() => navigate("/notification")}>
                        {alertCount > 0 ?
                            <>
                                <img src={Alarms} alt="alarm" />
                                <div className="absolute w-4 h-4 text-[12px] text-center text-white bg-[#f00] rounded-full right-[-4px] top-[-4px]">{alertCount}</div>
                            </> :
                            <img src={Alarms2} alt="alarm" />
                        }
                    </div>
                    <div className="w-[38px] h-[36px] rounded-[18px]  items-center justify-center lg:flex hidden cursor-pointer">
                        <input type="file" id="avatar" className="hidden" />
                        <img
                            className="w-9 h-9 rounded-[36px] object-cover" alt="avatar"
                            src={avatarUrl ? process.env.REACT_APP_SERVER_STOR_URL + avatarUrl : Avatar}
                        />
                    </div>
                    <p className="hidden lg:flex text-black5">{userName}</p>
                    <img
                        className="lg:hidden flex cursor-pointer" alt="menu"
                        src={MenuIcon}
                        onClick={() => props.onMenuClick()}
                    />
                </div>
            </div >
        </>
    );
};

export default Header;
