// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Menu, MenuHandler, MenuList, MenuItem, Checkbox } from "@material-tailwind/react";

import FilterIcon from "../../assets/icons/filter.svg";
import PlusIcon from "../../assets/icons/plus.svg";
import { FaEllipsisV, FaAngleDown } from "react-icons/fa";
import Pagination from "../../components/Pagination";
import SearchInput from "../../components/Form/SearchInput";
import IconButton from "../../components/Buttons/IconButton";
import { DATETIME_FORMAT } from "../../utils/contants";
import dayjs from "dayjs";
import { deleteCMSItems, getCMSData } from "../../services/axios";
import { showConfirmBox } from "../../utils/utils";
import { toast } from "react-toastify";
import { useOnClickOutside } from "usehooks-ts";
import LoadingSpinner from "../../components/loading";
import DefaultSelect from "../../components/Form/DefaultSelect";


const CMSFaqs = () => {

    const navigate = useNavigate();

    const [isFilterMenu, setIsFilterMenu] = useState(false);
    const [filterStatus, setFilterStatus] = useState('');
    const [filterVisibility, setFilterVisibility] = useState('');

    const [dataList, setDataList] = useState([]);
    const [checkedIds, setCheckedIds] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        loadPage();
    }, [filterStatus, filterVisibility]);

    const loadPage = async (page = 1) => {
        setIsLoading(true);
        const res = await getCMSData('faq', {
            page, pageSize, keyword,
            status: filterStatus,
            visibility: filterVisibility,
        });
        if (res.status === 200) {
            setDataList(res.data.items);
            setTotalCount(res.data.totalCount);
        }
        setCurPage(page);
        setIsLoading(false);
    }

    const handleFilterMenu = () => {
        setIsFilterMenu(!isFilterMenu);
    }

    const filterBtnRef = useRef(null);
    const filterRef = useRef(null);
    useOnClickOutside([filterRef, filterBtnRef], (e) => {
        setIsFilterMenu(false);
    });

    const handleCheckAll = (checked) => {
        setCheckedIds(checked ? dataList.map(item => item._id) : []);
    }

    const handleCheck = (item, checked) => {
        if (checked && !checkedIds.includes(item._id)) {
            setCheckedIds([...checkedIds, item._id]);
        } else if (!checked && checkedIds.includes(item._id)) {
            setCheckedIds(checkedIds.filter(id => id !== item._id));
        }
    }

    const handleAdd = () => {
        navigate("/cms/faq/edit");
    }

    const handleEdit = (item) => {
        navigate("/cms/faq/edit", {
            state: item
        });
    }

    const handleRemove = (item) => {
        if (item === null && checkedIds.length == 0) {
            return toast.warning("Please select items!");
        }

        showConfirmBox("Warning", "Are you sure to delete?",
            async () => {
                let ids = checkedIds;
                if (item) {
                    ids = [item._id];
                }
                const res = await deleteCMSItems(ids, 'faq');
                if (res.status === 200) {
                    toast.success("Successfully deleted!");
                    loadPage(curPage);
                } else {
                    toast.error(res.data.message);
                }
            });
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                    <div>
                        <div className="text-black text-[20px] font-Urbanist-Bold">
                            FAQ Management
                        </div>
                        <div className="text-gray6 text-[12px] font-[600]">
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                    <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                        <div className="w-full flex justify-between items-center mb-[16px]">
                            <p className="text-black text-[18px] font-[600]">Blogs</p>
                        </div>
                        <div className="w-full flex justify-between items-center mb-[16px] gap-2">
                            <SearchInput
                                bgClass="bg-white" className='border border-border2' placeholder="Search FAQ"
                                value={keyword} setValue={setKeyword} onPressEnter={loadPage}
                            />
                            <div className="flex justify-between items-center gap-4 relative">
                                <div className="flex justify-center items-center border border-border2 rounded-lg gap-[10px] text-[14px] text-[#7A7E82] px-[10px] py-[10px] cursor-pointer" onClick={handleFilterMenu} ref={filterBtnRef}>
                                    <img src={FilterIcon} alt="filter" />
                                    <span className="hidden md:block">Filter</span>
                                    <FaAngleDown />
                                </div>
                                {isFilterMenu &&
                                    <div className="absolute right-0 md:left-0 md:right-auto top-12 bg-white border border-border2 rounded-md shadow-lg p-4 flex flex-col gap-3 items-center" ref={filterRef}>
                                        <div className="flex flex-row justify-between items-center gap-4">
                                            <div className="w-[80px]">Status</div>
                                            <div className="w-[150px]">
                                                <DefaultSelect
                                                    value={filterStatus}
                                                    setValue={setFilterStatus}
                                                    className="h-[40px] py-2"
                                                    options={[
                                                        { value: '', label: "All" },
                                                        { value: 0, label: "Pending" },
                                                        { value: 1, label: "Published" },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between items-center gap-4">
                                            <div className="w-[80px]">Visibility</div>
                                            <div className="w-[150px]">
                                                <DefaultSelect
                                                    value={filterVisibility}
                                                    setValue={setFilterVisibility}
                                                    className="h-[40px] py-2"
                                                    options={[
                                                        { value: '', label: "All" },
                                                        { value: 0, label: "Private" },
                                                        { value: 1, label: "Public" },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <button className="text-[14px] text-[#FF5C5C] font-[500] hover:text-white hover:bg-[#FF5C5C] border border-[#FF303033] p-[10px] hidden md:block md:w-[130px] rounded-[6px]" onClick={() => handleRemove(null)}>Delete</button>
                                <IconButton icon={PlusIcon} text="Create FAQ" TxSize="text-[14px]" py="py-[10px]" px="px-[10px]" className="w-[150px] hidden md:flex" onClick={handleAdd} />
                            </div>
                        </div>
                        <div className="w-full mb-[16px] flex justify-between gap-4 md:hidden">
                            <button className="text-[14px] text-[#FF5C5C] font-[500] hover:text-white hover:bg-[#FF5C5C] border border-[#FF303033] p-[10px] w-full rounded-[6px]" onClick={() => handleRemove(null)}>Delete</button>
                            <IconButton icon={PlusIcon} text="Create FAQ" TxSize="text-[14px]" py="py-[10px]" px="px-[10px]" className="w-full" onClick={handleAdd} />
                        </div>
                        <div className="flex flex-col w-full overflow-x-auto pt-[16px]">
                            <div className="w-full min-w-fit flex items-center justify-between gap-1 pb-1 border-b border-border2">
                                <p className="w-[25%] min-w-[250px] text-gray6 text-[14px] font-[500]">
                                    <Checkbox
                                        color="green"
                                        label="Title"
                                        checked={dataList.length == checkedIds.length}
                                        onChange={e => handleCheckAll(e.target.checked)}
                                    />
                                </p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Status</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Visibility</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Publish On</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Created At</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Updated At</p>
                                <p className="w-[5%] min-w-[50px] text-gray6 text-[14px] font-[500] text-end">Action</p>
                            </div>
                            {dataList.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-between py-1 gap-1 border-b border-border2">
                                    <div className="w-[25%] min-w-[250px]">
                                        <p className="text-[14px] font-[500] text-black">
                                            <Checkbox
                                                color="green"
                                                label={item.title}
                                                checked={checkedIds.includes(item._id)}
                                                onChange={e => handleCheck(item, e.target.checked)}
                                            />
                                        </p>
                                    </div>
                                    <div className="w-[10%] min-w-[100px]">
                                        <p className="text-[14px] font-[500] text-black">
                                            {item.publishStatus === 1 ? 'Published' : 'Pending'}
                                        </p>
                                    </div>
                                    <div className="w-[10%] min-w-[100px]">
                                        {item.visibility === 1 ?
                                            <span className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer">
                                                Public
                                            </span> :
                                            <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                                Private
                                            </span>
                                        }
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.publishedAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.updatedAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[5%] min-w-[50px] flex justify-end">
                                        <Menu placement="bottom-end">
                                            <MenuHandler>
                                                <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                            </MenuHandler>
                                            <MenuList>
                                                <MenuItem className="text-black text-[14px]" onClick={() => handleEdit(item)}>Edit</MenuItem>
                                                <MenuItem className="text-[#FF5C5C] text-[14px]" onClick={() => handleRemove(item)}>Delete</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <Pagination
                            totalCount={totalCount}
                            onGoToPage={(page) => loadPage(page)}
                            pageSize={pageSize}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CMSFaqs;