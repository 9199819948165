"use client";

import React, { useState, useEffect } from "react";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { toast } from 'react-toastify';

import LogoImg from "../../assets/image/logo.png"
import IconButton from "../../components/Buttons/IconButton";
import { isValidateEmail } from "../../utils/utils";
import { useAuth } from "../../hooks/useAuth";
import { signInUser } from "../../services/axios";
import { useNavigate } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();
    const { login } = useAuth();

    const [showPassword, setShowPassword] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [userPasswd, setUserPasswd] = useState('')
    const [isRememberMe, setIsRememberMe] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("invest_hulk_email")) {
            setUserEmail(localStorage.getItem("invest_hulk_email") ?? '');
        }
        if (localStorage.getItem("invest_hulk_pwd")) {
            setUserPasswd(localStorage.getItem("invest_hulk_pwd") ?? '');
        }
    }, []);

    const handleLogin = async () => {
        if (isRememberMe) {
            localStorage.setItem("invest_hulk_email", userEmail);
            localStorage.setItem("invest_hulk_pwd", userPasswd);
        }

        if (userEmail === '') {
            return toast.info('Please Input the Email Address!');
        }
        if (!isValidateEmail(userEmail)) {
            return toast.info('Email Address is incorrect!');
        }
        if (userPasswd === '') {
            return toast.info('Please Input the Password!');
        }

        const res = await signInUser({ email: userEmail, password: userPasswd });
        if (res.status === 200) {
            const userData = res.data.data;
            if (userData.otpEnabled) {
                navigate("/2fa", {
                    state: userData
                });
            } else {
                login(userData);
            }
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <div className="w-screen min-h-screen bg-white flex flex-col items-center px-[20px]">
            <div className="flex items-center gap-x-5 pt-[168px]">
                <img className="w-[55px] h-[53px]" src={LogoImg} alt="Logo" />
                <p className="text-[32px] text-black5 font-[800] font-Urbanist-Bold">Hulk Cars</p>
            </div>
            <div className="w-full sm:w-[500px] flex flex-col items-start mt-[100px]">
                <form method='POST' className="w-full h-full flex flex-col items-start">
                    <div className="flex flex-col w-full items-start gap-3">
                        <label className="text-black5 text-[16px] font-[400] font-Urbanist">Email Address</label>
                        <input
                            placeholder="example@example.com"
                            type="email"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            className="w-full bg-[#F3F3F3] rounded-md p-[18px_24px] text-black5 border border-[#0000001A] outline-none"
                        />
                    </div>

                    <div className="flex flex-col w-full items-start gap-3 mt-[23px]">
                        <label className="text-black5 text-[16px] font-[400] font-Urbanist">Password</label>
                        <div className="relative w-full flex item">
                            <input
                                value={userPasswd}
                                onChange={(e) => setUserPasswd(e.target.value)}
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                className="w-full bg-[#F3F3F3] rounded-md p-[18px_24px] text-black5 border border-[#0000001A] outline-none"
                            />
                            {showPassword ?
                                <FaRegEye
                                    onMouseUp={() => setShowPassword(false)}
                                    className="absolute top-[50%] -translate-y-[50%] right-[23px] text-[#717171] w-[21px] h-[12px] cursor-pointer" />
                                :
                                <FaRegEyeSlash
                                    onMouseDown={() => setShowPassword(true)}
                                    className="absolute top-[50%] -translate-y-[50%] right-[23px] text-[#717171] w-[21px] h-[12px] cursor-pointer" />
                            }
                        </div>
                    </div>

                    <div className="w-full flex justify-center sm:justify-between items-center mt-4">
                        <div className=" flex justify-center items-center gap-4">
                            <input type="checkbox" className="w-5 h-5 rounded-sm" id="rememberMe" checked={isRememberMe} onChange={(e) => setIsRememberMe(e.target.checked)} />
                            <label className="text-[14px] font-[400] text-unseletedText" htmlFor="rememberMe">Remember me</label>
                        </div>
                    </div>

                    <IconButton text="Log in" className="w-[354px] mt-[53px] self-center" onClick={handleLogin} />
                </form>
            </div>
        </div>
    );
};

export default Login;