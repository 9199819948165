import Datepicker from "react-tailwindcss-datepicker";

const DateSinglePicker = ({
  label = "",
  required = false,
  date = null,
  setDate = null,
}) => {

  return (
    <div className="flex flex-col gap-2 w-[100%]">
      {label && (
        <p className="text-black text-[16px]">
          {label}
          {required && <span className="text-[#48B825]">*</span>}
        </p>
      )}
      <Datepicker
        useRange={false}
        asSingle={true}
        value={{
          startDate: date,
          endDate: date
        }}
        onChange={(newValue) => {
          setDate && setDate(newValue.startDate)
        }}
        inputClassName="w-full h-[49px] p-3 border border-[#0000001A] rounded-[8px] outline-none"
      />
    </div>
  );
};

export default DateSinglePicker;
