import { Route, Routes, BrowserRouter } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MainLayout from "../layout/MainLayout";
import Login from "../pages/auth/Login";
import TwoFactorAuth from "../pages/auth/TwoFactorAuth";
import Dashboard from "../pages/dashboard/Dashboard";
import Statistics from "../pages/statistics/Statistics";
import Users from "../pages/users/Users";
import UserProfile from "../pages/users/UserProfile";
import UserActivities from "../pages/users/UserActivities";
import Cars from "../pages/cars/Cars";
import CarEdit from "../pages/cars/CarEdit";
import Projects from "../pages/projects/Projects";
import ProjectInvestors from "../pages/projects/ProjectInvestors";
import Transactions from "../pages/transactions/Transactions";
import AllTransactions from "../pages/transactions/AllTransactions";
import Contracts from "../pages/contracts/Contracts";
import ContractEdit from "../pages/contracts/ContractEdit";
import CMSBlogs from "../pages/cms/CMSBlogs";
import BlogEdit from "../pages/cms/BlogEdit";
import CMSPages from "../pages/cms/CMSPages";
import CMSFaqs from "../pages/cms/CMSFaqs";
import FaqEdit from "../pages/cms/FaqEdit";
import PageEdit from "../pages/cms/PageEdit";
import Roles from "../pages/roles/Roles";
import RoleEdit from "../pages/roles/RoleEdit";
import Notifications from "../pages/notifications/Notifications";
import Admins from "../pages/roles/Admins";
import Settings from "../pages/settings/Settings";
import PaymentWithdrawals from "../pages/transactions/PaymentWithdrawals";
import PaymentDetail from "../pages/transactions/PaymentDetail";
import WithdrawalDetail from "../pages/transactions/WithdrawalDetail";
import { AuthProvider } from "../hooks/useAuth";
import NFTs from "../pages/cars/NFTs";

export default function Router() {

    return (
        <>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<MainLayout />}>
                            <Route index element={<Dashboard />} />
                            <Route path="statistics" element={<Statistics />} />
                            <Route path="users" element={<Users />} />
                            <Route path="users/profile" element={<UserProfile />} />
                            <Route path="users/activities" element={<UserActivities />} />
                            <Route path="users/transactions" element={<AllTransactions />} />
                            <Route path="cars" element={<Cars />} />
                            <Route path="cars/edit" element={<CarEdit />} />
                            <Route path="projects" element={<Projects />} />
                            <Route path="projects/investors" element={<ProjectInvestors />} />
                            <Route path="transactions" element={<Transactions />} />
                            <Route path="transactions/all" element={<AllTransactions />} />
                            <Route path="pws" element={<PaymentWithdrawals />} />
                            <Route path="pws/payment" element={<PaymentDetail />} />
                            <Route path="pws/withdrawal" element={<WithdrawalDetail />} />
                            <Route path="nfts" element={<NFTs />} />
                            <Route path="cms/blog" element={<CMSBlogs />} />
                            <Route path="cms/blog/edit" element={<BlogEdit />} />
                            <Route path="cms/page" element={<CMSPages />} />
                            <Route path="cms/page/edit" element={<PageEdit />} />
                            <Route path="cms/faq" element={<CMSFaqs />} />
                            <Route path="cms/faq/edit" element={<FaqEdit />} />
                            <Route path="contracts" element={<Contracts />} />
                            <Route path="contracts/edit" element={<ContractEdit />} />
                            <Route path="roles" element={<Admins />} />
                            <Route path="admins" element={<Admins />} />
                            <Route path="roles/edit" element={<RoleEdit />} />
                            <Route path="settings" element={<Settings />} />
                            <Route path="notification" element={<Notifications />} />
                        </Route>
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="/2fa" element={<TwoFactorAuth />}></Route>
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
            <ToastContainer autoClose={2000} />
        </>
    )
}