
const DefaultSelect = ({
  label = "",
  required = false,
  placeholder = "",
  options = [],
  value = 0,
  setValue = null,
  className = '',
}) => {
  return (
    <div className={`flex flex-col gap-2 w-[100%]`}>
      {label && (
        <p className="text-black text-[16px]">
          {label}
          {required && <span className="text-[#48B825]">*</span>}
        </p>
      )}
      <select
        placeholder={placeholder}
        onChange={(e) => {
          setValue && setValue(e.target.value)
        }}
        value={value}
        className={`w-[100%] h-[49px] p-3 bg-white border border-[#0000001A] rounded-[8px] outline-none text-base text-gray5 ${className}`}
      >
        {options.map((item, index) => (
          <option
            value={typeof item == "object" ? item?.value : item}
            key={index}
          >
            {typeof item == "object" ? item?.label : item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DefaultSelect;
