// @ts-nocheck

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { FaAngleRight } from "react-icons/fa";
import SettingGeneral from "./SettingGeneral";
import SettingSecurity from "./SettingSecurity";
import SettingNotification from "./SettingNotification";
import SettingFinancial from "./SettingFinancial";
import { getConfigs, updateConfigs } from "../../services/axios";
import { toast } from "react-toastify";

const Settings = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 720px)' })

    const [activeTab, setActiveTab] = useState(0);
    const [configs, setConfigs] = useState({});

    useEffect(() => {
        loadConfigs();
    }, [activeTab]);

    const loadConfigs = async () => {
        const res = await getConfigs();
        if (res.status === 200) {
            setConfigs(res.data);
        }
    }

    const handleSave = async (values) => {
        let needUpdate = false;
        for (const key in values) {
            if (values[key] != configs?.[key]) {
                needUpdate = true;
                break;
            }
        }
        if (!needUpdate) {
            return;
        }

        const res = await updateConfigs(values);
        if (res.status === 200) {
            loadConfigs();
            toast.success("Updated Successfully");
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <div className="w-full flex flex-col items-center">
            {(!isTabletOrMobile || activeTab === 0) &&
                <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                    <div>
                        <div className="text-black text-[20px] font-Urbanist-Bold">
                            Settings
                        </div>
                        <div className="text-gray6 text-[12px] font-[600]">
                            Adjust your settings
                        </div>
                    </div>
                </div>
            }

            {isTabletOrMobile ?
                <>
                    {activeTab === 0 &&
                        <div className="w-full flex flex-col">
                            <div className="flex justify-between px-8 py-5 border-b border-border2 cursor-pointer active:bg-white" onClick={() => setActiveTab(1)}>
                                <span className="text-black text-[14px] font-[600]">General Information</span>
                                <FaAngleRight className="text-black" />
                            </div>
                            <div className="flex justify-between px-8 py-5 border-b border-border2 cursor-pointer active:bg-white" onClick={() => setActiveTab(2)}>
                                <span className="text-black text-[14px] font-[600]">Security</span>
                                <FaAngleRight className="text-black" />
                            </div>
                            <div className="flex justify-between px-8 py-5 border-b border-border2 cursor-pointer active:bg-white" onClick={() => setActiveTab(3)}>
                                <span className="text-black text-[14px] font-[600]">Notification</span>
                                <FaAngleRight className="text-black" />
                            </div>
                            <div className="flex justify-between px-8 py-5 border-b border-border2 cursor-pointer active:bg-white" onClick={() => setActiveTab(4)}>
                                <span className="text-black text-[14px] font-[600]">Financial settings</span>
                                <FaAngleRight className="text-black" />
                            </div>
                        </div>
                    }
                    <div className="w-full p-6">
                        {activeTab === 1 && <SettingGeneral onBack={() => setActiveTab(0)} configs={configs} onSave={handleSave} />}
                        {activeTab === 2 && <SettingSecurity onBack={() => setActiveTab(0)} configs={configs} onSave={handleSave} />}
                        {activeTab === 3 && <SettingNotification onBack={() => setActiveTab(0)} configs={configs} onSave={handleSave} />}
                        {activeTab === 4 && <SettingFinancial onBack={() => setActiveTab(0)} configs={configs} onSave={handleSave} />}
                    </div>
                </>
                :
                <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                    <div className="bg-white w-full p-6 rounded-[8px] flex gap-6 h-fit">
                        <div className="grow-0 w-[170px] flex-col gap-2 hidden md:flex">
                            <div className={`w-full text-[14px] p-[10px_16px] cursor-pointer select-none rounded-[8px] hover:bg-[#44972a] hover:text-white ${(activeTab === 0 || activeTab === 1) ? 'text-white bg-[#48B825]' : 'text-gray6'} `}
                                onClick={() => setActiveTab(1)}
                            >
                                General Information
                            </div>
                            <div className={`w-full text-[14px] p-[10px_16px] cursor-pointer select-none rounded-[8px] hover:bg-[#44972a] hover:text-white ${activeTab === 2 ? 'text-white bg-[#48B825]' : 'text-gray6'} `}
                                onClick={() => setActiveTab(2)}
                            >
                                Security
                            </div>
                            <div className={`w-full text-[14px] p-[10px_16px] cursor-pointer select-none rounded-[8px] hover:bg-[#44972a] hover:text-white ${activeTab === 3 ? 'text-white bg-[#48B825]' : 'text-gray6'} `}
                                onClick={() => setActiveTab(3)}
                            >
                                Notification
                            </div>
                            <div className={`w-full text-[14px] p-[10px_16px] cursor-pointer select-none rounded-[8px] hover:bg-[#44972a] hover:text-white ${activeTab === 4 ? 'text-white bg-[#48B825]' : 'text-gray6'} `}
                                onClick={() => setActiveTab(4)}
                            >
                                Financial settings
                            </div>
                        </div>
                        <div className="grow pl-8 pt-2 border-l border-border2 min-h-[660px]">
                            {(activeTab === 0 || activeTab === 1) && <SettingGeneral configs={configs} onSave={handleSave} />}
                            {activeTab === 2 && <SettingSecurity configs={configs} onSave={handleSave} />}
                            {activeTab === 3 && <SettingNotification configs={configs} onSave={handleSave} />}
                            {activeTab === 4 && <SettingFinancial configs={configs} onSave={handleSave} />}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Settings;