//@ts-nocheck
"use client";

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from 'react-toastify';

import LogoImg from "../../assets/image/logo.png"
import QRCodeImg from "../../assets/image/qrcode.svg"
import IconButton from "../../components/Buttons/IconButton";
import { validateOTP } from "../../services/axios";
import { useAuth } from "../../hooks/useAuth";
import { useKeyPress } from "../../hooks/useKeyPress";

const TwoFactorAuth = () => {

    const navigate = useNavigate();
    const userData = useLocation().state;
    const { login } = useAuth();

    const [isScanCode, setIsScanCode] = useState(false);

    const [codes, setCodes] = useState(["", "", "", "", "", ""]);

    // const keys = [
    //     useKeyPress('0'), useKeyPress('1'), useKeyPress('2'), useKeyPress('3'), useKeyPress('4'),
    //     useKeyPress('5'), useKeyPress('6'), useKeyPress('7'), useKeyPress('8'), useKeyPress('9'),
    // ];
    // const backspaceKey = useKeyPress('Backspace');

    // useEffect(() => {
    //     let num = -1;
    //     for (let i = 0; i < keys.length; i++) {
    //         if (keys[i]) {
    //             num = i;
    //             break;
    //         }
    //     }
    //     if (num >= 0) {
    //         for (let i = 0; i < 6; i++) {
    //             if (codes[i] === "") {
    //                 setTimeout(() => {
    //                     handleInputCode(num, i);
    //                 }, 200);
    //                 break;
    //             }
    //         }
    //     }
    // }, [keys]);

    // useEffect(() => {
    //     if (backspaceKey) {
    //         for (let i = 5; i >= 0; i--) {
    //             if (codes[i] !== "") {
    //                 handleInputCode("", i);
    //                 break;
    //             }
    //         }
    //     }
    // }, [backspaceKey])


    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        if (!userData) {
            navigate("/login");
        }
    }, []);

    const handleResend = () => {
        return toast.success('Resend Succeed!');
    }

    const handleScan = () => {
        setIsScanCode(false);
    }

    const handleInputCode = (value: any, index: any) => {
        const newCodes = [...codes];
        newCodes[index] = value;
        setCodes(newCodes);
    }

    const handleValidate = async () => {
        if (codes.join("").length < 6) {
            return toast.error('verification code is invalid!');
        }

        const res = await validateOTP({
            email: userData.email, token: codes.join("")
        });
        if (res.status === 200) {
            login(userData);
        } else {
            setCodes(["", "", "", "", "", ""]);
            return toast.error('verification code is invalid!');
        }
    }

    return (
        <div className="w-screen min-h-screen bg-white flex flex-col items-center px-[20px]">
            <div className="flex items-center gap-x-5 pt-[168px]">
                <img className="w-[55px] h-[53px]" src={LogoImg} alt="Logo" />
                <p className="text-[32px] text-black5 font-[800] font-Urbanist-Bold">Hulk Cars</p>
            </div>
            <div className="w-full md:w-[760px] flex flex-col items-center mt-[60px]">
                {isScanCode ?
                    <>
                        <p className="text-[14px] text-black5 font-[600] font-Urbanist mb-2">
                            Scan QR code with your oauth app
                        </p>
                        <div className="mb-4">
                            <img className="w-[360px] h-[360px]" src={QRCodeImg} alt="qrcode" onClick={handleScan} />
                        </div>
                        <p className="text-[14px] text-unseletedText font-[500] font-Urbanist mb-2">
                            Didn’t get a code? <span onClick={handleResend} className="text-[#48B825] cursor-pointer">Resend</span>
                        </p>
                    </> :
                    <>
                        <p className="text-[20px] sm:text-[32px] text-black5 font-[600] font-Urbanist text-center mb-2">
                            Enter your verification code
                        </p>
                        <p className="w-full sm:w-[450px] text-center text-[14px] text-unseletedText font-[500] font-Urbanist mb-[45px]">
                            We have sent 6-digit code to your authentication application such as Google Authenticator or other.
                        </p>
                        <div className="flex justify-center gap-[13px] mb-[16px]">
                            {codes.map((code, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={code}
                                    // readOnly
                                    onChange={(e) => {
                                        handleInputCode(e.target.value, index);
                                        if (index < 5 && inputRefs[index + 1]?.current && e.target.value) {
                                            inputRefs[index + 1].current?.focus();
                                            inputRefs[index + 1].current?.select();
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.code !== "Backspace" && e.code !== "Delete" && !(Number(e.key) >= 0 && Number(e.key) <= 9)) {
                                            e.preventDefault();
                                        }
                                        if (e.code === "Backspace" && index > 0 && inputRefs[index - 1]?.current && e.target.value === "") {
                                            inputRefs[index - 1].current?.focus();
                                            inputRefs[index - 1].current?.select();
                                        }
                                    }}
                                    ref={inputRefs[index]}
                                    className="w-[44px] h-[54px] md:w-[100px] md:h-[120px] text-[16px] md:text-[32px] bg-[#F3F3F3] rounded-md text-black5 border border-[#0000001A] text-center outline-none"
                                />
                            ))}
                        </div>
                        <p className="text-[12px] text-[#717171] font-[500] font-Urbanist mb-[50px]">
                            Code expires in 5:00
                        </p>
                        <IconButton text="Validate" className="w-[758px] mb-[20px]" onClick={handleValidate} />
                        <p className="text-[14px] text-gray6 font-[500] font-Urbanist">
                            Didn’t get a code? <span onClick={handleResend} className="text-[#48B825] cursor-pointer">Resend</span>
                        </p>
                    </>
                }
            </div>
        </div>
    );
};

export default TwoFactorAuth;