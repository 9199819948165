// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";
import { FaEllipsisV, FaAngleDown } from "react-icons/fa";
import dayjs from "dayjs";

import FilterIcon from "../../assets/icons/filter.svg";
import PlusIcon from "../../assets/icons/plus.svg";
import Pagination from "../../components/Pagination";
import SearchInput from "../../components/Form/SearchInput";
import IconButton from "../../components/Buttons/IconButton";
import { addDocument, getDocuments } from "../../services/axios";
import { DATETIME_FORMAT, DocumentTypes } from "../../utils/contants";
import EditDialog from "./EditDialog";
import { toast } from "react-toastify";
import { getFileUrl } from "../../utils/utils";
import LoadingSpinner from "../../components/loading";
import DefaultSelect from "../../components/Form/DefaultSelect";
import { useOnClickOutside } from "usehooks-ts";


const Contracts = () => {

    const navigate = useNavigate();

    const [isFilterMenu, setIsFilterMenu] = useState(false);
    const [filterStatus, setFilterStatus] = useState('');
    const [filterType, setFilterType] = useState('');

    const [dataList, setDataList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [keyword, setKeyword] = useState("");

    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        loadPage();
    }, [filterStatus, filterType]);

    const loadPage = async (page = 1) => {
        setIsLoading(true);
        const res = await getDocuments({
            page, pageSize, keyword,
            type: filterType,
            status: filterStatus
        });
        if (res.status === 200) {
            setDataList(res.data.items);
            setTotalCount(res.data.totalCount);
        }
        setCurPage(page);
        setIsLoading(false);
    }

    const handleFilterMenu = () => {
        setIsFilterMenu(!isFilterMenu);
    }

    const filterBtnRef = useRef(null);
    const filterRef = useRef(null);
    useOnClickOutside([filterRef, filterBtnRef], (e) => {
        setIsFilterMenu(false);
    });

    const handleAdd = async (values) => {
        const res = await addDocument(values);
        if (res.status === 200) {
            toast.success("Add Document Succeed!");
            setIsModalVisible(false);
            loadPage();
        } else {
            toast.error(res.data.message);
        }
    }

    const handleView = (item) => {
        navigate("/contracts/edit", {
            state: item
        });
    }

    const handleDownload = (item) => {
        if (item.file) {
            window.open(getFileUrl(item.file.path), "_blank");
        }
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                    <div>
                        <div className="text-black text-[20px] font-Urbanist-Bold">
                            Documents and Contracts Management
                        </div>
                        <div className="text-gray6 text-[12px] font-[600]">
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                    <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                        <div className="w-full flex justify-between items-center mb-[16px]">
                            <p className="text-black text-[18px] font-[600]">Document Library</p>
                        </div>
                        <div className="w-full mb-[16px] md:hidden">
                            <IconButton icon={PlusIcon} text="Add Document" TxSize="text-[14px]" py="py-[10px]" px="px-[10px]" className="w-full"
                                onClick={() => setIsModalVisible(true)}
                            />
                        </div>
                        <div className="w-full flex justify-between items-center mb-[32px] gap-2">
                            <SearchInput
                                bgClass="bg-white" className='border border-border2' placeholder="Search Documents"
                                value={keyword} setValue={setKeyword} onPressEnter={loadPage}
                            />
                            <div className="flex justify-between items-center gap-4 relative">
                                <div className="flex justify-center items-center border border-border2 rounded-lg gap-[10px] text-[14px] text-[#7A7E82] px-[10px] py-[10px]" onClick={handleFilterMenu} ref={filterBtnRef}>
                                    <img src={FilterIcon} alt="filter" />
                                    <span className="hidden md:block">Filter</span>
                                    <FaAngleDown />
                                </div>
                                {isFilterMenu &&
                                    <div className="absolute right-0 md:left-0 md:right-auto top-12 bg-white border border-border2 rounded-md shadow-lg p-4 flex flex-col gap-3 items-center" ref={filterRef}>
                                        <div className="flex flex-row justify-between items-center gap-4">
                                            <div className="w-[80px]">Type</div>
                                            <div className="w-[150px]">
                                                <DefaultSelect
                                                    value={filterType}
                                                    setValue={setFilterType}
                                                    className="h-[40px] py-2"
                                                    options={[
                                                        { value: '', label: "All" },
                                                        ...(DocumentTypes.map(item => ({ value: item, label: item })))
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between items-center gap-4">
                                            <div className="w-[80px]">Status</div>
                                            <div className="w-[150px]">
                                                <DefaultSelect
                                                    value={filterStatus}
                                                    setValue={setFilterStatus}
                                                    className="h-[40px] py-2"
                                                    options={[
                                                        { value: '', label: "All" },
                                                        { value: 0, label: "Inactive" },
                                                        { value: 1, label: "Active" },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <IconButton icon={PlusIcon} text="Add Document" TxSize="text-[14px]" py="py-[10px]" px="px-[10px]" className="w-[160px] hidden md:flex"
                                    onClick={() => setIsModalVisible(true)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col w-full overflow-x-auto min-h-[300px]">
                            <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                                <p className="w-[30%] min-w-[200px] text-gray6 text-[14px] font-[500]">Document Name</p>
                                <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Type</p>
                                <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Date Created</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Status</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-end">Action</p>
                            </div>
                            {dataList.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                    <div className="w-[30%] min-w-[200px] overflow-hidden">
                                        <p className="text-[14px] font-[500] text-black break-all">{item.name}</p>
                                    </div>
                                    <div className="w-[20%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{item.type}</p>
                                    </div>
                                    <div className="w-[20%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[10%] min-w-[100px]">
                                        {item.status === 1 ?
                                            <span className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer">
                                                Active
                                            </span> :
                                            <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                                Inactive
                                            </span>
                                        }
                                    </div>
                                    <div className="w-[10%] min-w-[100px] flex justify-end">
                                        <Menu placement="bottom-end">
                                            <MenuHandler>
                                                <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                            </MenuHandler>
                                            <MenuList>
                                                <MenuItem className="text-black text-[14px]" onClick={() => handleView(item)}>View</MenuItem>
                                                <MenuItem className="text-black text-[14px]" onClick={() => handleDownload(item)}>Download</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <Pagination
                            totalCount={totalCount}
                            onGoToPage={(page) => loadPage(page)}
                            pageSize={pageSize}
                        />
                    </div>
                </div>

                <EditDialog
                    open={isModalVisible}
                    onClose={(result) => setIsModalVisible(false)}
                    isAdd={true}
                    onSubmit={(values) => handleAdd(values)}
                />
            </div>
        </>
    );
};

export default Contracts;