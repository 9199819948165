// @ts-nocheck

import React, { useEffect, useState } from "react";

import IconButton from "../../components/Buttons/IconButton";
import Input from "../../components/Form/Input";
import { Switch } from "@material-tailwind/react";

const SettingNotification = ({ configs, onSave, onBack = () => { } }) => {

    const [isPushNotification, setIsPushNotification] = useState(configs?.isPushNotification ?? false);
    const [isSendEmail, setIsSendEmail] = useState(configs?.isSendEmail ?? false);
    const [isSendSMS, setIsSendSMS] = useState(configs?.isSendSMS ?? false);

    useEffect(() => {
        
        onSave({ isPushNotification, isSendEmail, isSendSMS });

    }, [isPushNotification, isSendEmail, isSendSMS]);

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center pb-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Notification
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                        Adjust your settings
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col items-start gap-4 bg-white rounded-[8px] p-5 md:p-0 md:pt-5 mt-5 md:mt-0">
                <div className="w-full flex items-center justify-between border-b pb-4 border-border2">
                    <span className="text-[16px] text-black">Push Notification Alerts</span>
                    <Switch color="green" checked={isPushNotification} onChange={e => setIsPushNotification(e.target.checked)} />
                </div>
                <div className="w-full flex items-center justify-between border-b pb-4 border-border2">
                    <span className="text-[16px] text-black">Send Email</span>
                    <Switch color="green" checked={isSendEmail} onChange={e => setIsSendEmail(e.target.checked)} />
                </div>
                <div className="w-full flex items-center justify-between border-b pb-4 border-border2">
                    <span className="text-[16px] text-black">Send SMS</span>
                    <Switch color="green" checked={isSendSMS} onChange={e => setIsSendSMS(e.target.checked)} />
                </div>
                <IconButton text="Back" TxSize="text-[14px]" py="py-[10px]" className="w-full md:hidden" onClick={onBack} />
            </div>
        </div>
    );
};

export default SettingNotification;