// @ts-nocheck

import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import IconButton from "../../components/Buttons/IconButton";
import EditDialog from "./EditDialog";
import { deleteDocument, updateDocument } from "../../services/axios";
import { toast } from "react-toastify";
import { showConfirmBox } from "../../utils/utils";

const ContractEdit = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [documentInfo, setDocumentInfo] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (location.state) {
            setDocumentInfo(location.state);
        }
    }, [location]);


    const handleUpdate = async (values) => {
        const res = await updateDocument(documentInfo._id, values);
        if (res.status === 200) {
            toast.success("Update Document Succeed!");
            setDocumentInfo(values);
            setIsModalVisible(false);
        } else {
            toast.error(res.data.message);
        }
    }
    const handleRemove = () => {
        showConfirmBox("Warning", "Are you sure to delete?",
            async () => {
                const res = await deleteDocument(documentInfo._id);
                if (res.status === 200) {
                    toast.success("Successfully deleted!");
                    navigate("/contracts");
                } else {
                    toast.error(res.data.message);
                }
            });
    }


    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Documents and Contracts Management
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                    <p className="text-black text-[18px] font-[600]">Document Details</p>
                    <div className="text-[16px] font-[500]">
                        <span className="text-gray6">Name: </span>
                        <span className="text-black">{documentInfo?.name}</span>
                    </div>
                    <div className="text-[16px] font-[500] max-w-[500px]">
                        <span className="text-gray6">Description: </span><br />
                        <span className="text-black">{documentInfo?.description}</span>
                    </div>
                    <div className="text-[16px] font-[500]">
                        <span className="text-gray6">Type: </span>
                        <span className="text-black">{documentInfo?.type}</span>
                    </div>
                    <div className="text-[16px] font-[500]">
                        <span className="text-gray6">Version: </span>
                        <span className="text-black">{documentInfo?.version}</span>
                    </div>
                    <div className="text-[16px] font-[500]">
                        <span className="text-gray6">Date Created: </span>
                        <span className="text-black">{documentInfo?.createdAt}</span>
                    </div>
                    <div className="text-[16px] font-[500]">
                        <span className="text-gray6">Date Updated: </span>
                        <span className="text-black">{documentInfo?.updatedAt}</span>
                    </div>
                    <div className="text-[16px] font-[500]">
                        <span className="text-gray6">Status: </span>
                        {documentInfo?.status === 1 ?
                            <span className="text-[#55BA68]">Active</span> :
                            <span className="text-[#F7BA00]">Inactive</span>
                        }
                    </div>

                    <div className="mt-[30px] w-full flex flex-col md:flex-row justify-center md:justify-start gap-4">
                        <IconButton text="Update Document" TxSize="text-[14px]" py="py-[10px]" className="w-full md:w-[180px]" onClick={() => setIsModalVisible(true)} />
                        <button className="text-[14px] text-[#FF5C5C] font-[500] hover:text-white hover:bg-[#FF5C5C] border border-[#FF303033] p-[10px] md:w-[180px] rounded-[6px]" onClick={handleRemove}>Remove</button>
                    </div>
                </div>
            </div>

            <EditDialog
                open={isModalVisible}
                onClose={(result) => setIsModalVisible(false)}
                isAdd={false}
                onSubmit={(values) => handleUpdate(values)}
                itemInfo={documentInfo}
            />
        </div>
    );
};

export default ContractEdit;