

const IconButton = ({
  text = "",
  className = "",
  icon = null,
  rightIcon = null,
  BgClass = "bg-primary",
  BgHoverClass="hover:bg-[#44972a]",
  TxClass = "text-[#fff]",
  TxSize = "text-[16px]",
  px = "px-[16px]",
  py = "py-[16px]",
  rounded = "rounded-md",
  onClick = () => {},
  capitalize = true,
  iconHeight = null,
}) => {
  return (
    <button
      className={`${className} ${px} ${py} max-w-full flex flex-row justify-center items-center gap-2 ${rounded} ${BgClass} ${BgHoverClass}`}
      onClick={(e) => { e.preventDefault(); onClick(); }}
    >
      {icon && (
        <img
          src={icon}
          height={iconHeight ? iconHeight : ""}
          alt="button-icon"
          style={{ maxWidth: "none" }}
        />
      )}
      {text && (
        <p className={`${TxClass} ${TxSize} font-[500] font-Urbanist ${capitalize ? "capitalize" : ""}`}>
          {text}
        </p>
      )}
      {rightIcon && <img src={rightIcon} alt="button-icon" />}
    </button>
  );
};

export default IconButton;
