// @ts-nocheck

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import DateSinglePicker from "../../components/Form/DatePicker";
import { getStatisticsCountriesInfo, getStatisticsMonthlyTransactions, getStatisticsMonthlyUsers, getStatisticsMonthlyVisitors, getStatisticsTransactions } from "../../services/axios";
import { dollarFormatter } from "../../utils/utils";

const barChartOptions = {
    chart: {
        height: 100,
        type: 'bar'
    },
    colors: ["#FBBF04"],
    tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return '<div class="chart-tooltip-container bg-black rounded-lg px-2 text-[12px] font-[500] text-white">' +
                '<span class="chart-tooltip-font rounded-lg bg-transparent border-0">$' + series[seriesIndex][dataPointIndex] + '</span>' +
                '</div>'
        }
    },
    plotOptions: {
        bar: {
            columnWidth: '15%',
            distributed: true,
            borderRadius: 5
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',]
    }
}
const _investChartData = [{
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
}];


const lineChartOptions = {
    chart: {
        toolbar: { show: false },
    },
    stroke: {
        curve: "smooth",
    },
    colors: ["#FBBF04"],
    markers: {
        size: 0,
        sizeOffset: 12,
        colors: '#FBBF04',
        strokeColors: '#FBBF04',
        strokeWidth: 2,
        strokeOpacity: 0.2,
        fillOpacity: 0.2,
        discrete: [],
        shape: "circle",
        radius: 1,
        offsetX: 0,
        offsetY: 0,
        showNullDataPoints: true,
    },
    dataLabels: {
        enabled: false,
        show: false
    },
    tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return '<div class="chart-tooltip-container bg-[#FBBF04] p-[7px_8px] text-[12px] font-[500] text-[#141414]">' +
                '<span class="chart-tooltip-font" >$' + series[seriesIndex][dataPointIndex] + '</span>' +
                '</div>'
        }
    },
    xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Aug", "Sep", "Oct", "Nov", "Dec"],
        labels: {
            style: {
                colors: "#717171",
                fontSize: '14px',
            }
        },
        tooltip: false
    },
    yaxis: {
        labels: {
            show: false
        },
        min: 0,
    },
};
const _salesChartData = [
    {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
];

const lineChartOptions2 = JSON.parse(JSON.stringify(lineChartOptions));
lineChartOptions2.tooltip.custom = ({ series, seriesIndex, dataPointIndex, w }) => {
    return '<div class="chart-tooltip-container bg-[#FBBF04] p-[7px_8px] text-[12px] font-[500] text-[#141414]">' +
        '<span class="chart-tooltip-font" >' + series[seriesIndex][dataPointIndex] + '</span>' +
        '</div>'
};
const _usersChartData = [
    {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
];


const pieChartOptions = {
    chart: {
        type: 'donut',
        dropShadow: {
            enabled: true,
            color: '#111',
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2
        }
    },
    colors: ['#FCBF07', '#78C9F9', '#AA57E8'],
    labels: ['Payment', 'Withdrawal', 'Invest'],
    stroke: {
        width: 0,
    },
    plotOptions: {
        pie: {
            donut: {
                labels: {
                    show: true,
                    total: {
                        showAlways: true,
                        show: true,
                        color: '#A5ACBA',
                        fontSize: 14
                    },
                    value: {
                        color: 'black',
                        fontSize: 25,
                        formatter: function (val) {
                            return val + "%";
                        }
                    }
                }
            }
        }
    },
    dataLabels: {
        enabled: false,
    },
    fill: {
        opacity: 1,
        pattern: {
            enabled: false,
        },
    },
    states: {
        hover: {
            filter: 'none'
        }
    },
    legend: {
        enabled: false,
        show: false
    },
}
const _buyingCategoriesData = [0, 0, 0]


const visitorOptions = {
    chart: {
        height: 100,
        type: 'bar'
    },
    colors: ["#FBBF04"],
    tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return '<div class="chart-tooltip-container bg-black rounded-lg px-2 text-[12px] font-[500] text-white">' +
                '<span class="chart-tooltip-font rounded-lg bg-transparent border-0">' + series[seriesIndex][dataPointIndex] + '</span>' +
                '</div>'
        }
    },
    plotOptions: {
        bar: {
            columnWidth: '15%',
            distributed: true,
            borderRadius: 5
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',]
    }
}
const _visitorsData = [{
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
}];


const regionStyle = {
    initial: {
        fill: "#e4e4e4",
        "fill-opacity": 0.9,
        stroke: "none",
        "stroke-width": 0,
        "stroke-opacity": 0
    },
    selected: {
        fill: '#FBBF04',
        "fill-opacity": 0.9,
        stroke: "none",
        "stroke-width": 0,
        "stroke-opacity": 0
    }
};

const Statistics = () => {

    const [selDate, setSelDate] = useState(new Date());

    const [totalInvestments, setTotalInvestments] = useState(_investChartData);
    const [salesData, setSalesData] = useState(_salesChartData);
    const [usersData, setUsersData] = useState(_usersChartData);
    const [buyingData, setBuyingData] = useState(_buyingCategoriesData);
    const [visitorsData, setVisitorsData] = useState(_visitorsData);

    const [customersByCountry, setCustomersByCountry] = useState([]);
    const [selectedCountryInfo, setSelectedCountryInfo] = useState({ country: "", count: 0, amount: 0 });

    useEffect(() => {
        loadMonthlyInvests();
        loadMonthlySales();
        loadMonthlyUsers();
        loadMonthlyVisitors();
        loadTransactionsInfo();
        loadCountriesInfo();
    }, []);

    const loadMonthlyInvests = async () => {
        const res = await getStatisticsMonthlyTransactions("invest");
        if (res.status === 200) {
            setTotalInvestments([{
                data: res.data.result.map(item => item.amount)
            }]);
        }
    }
    const loadMonthlySales = async () => {
        const res = await getStatisticsMonthlyTransactions();
        if (res.status === 200) {
            setSalesData([{
                data: res.data.result.map(item => item.amount)
            }]);
        }
    }
    const loadMonthlyUsers = async () => {
        const res = await getStatisticsMonthlyUsers();
        if (res.status === 200) {
            setUsersData([{
                data: res.data.result.map(item => item.count)
            }]);
        }
    }
    const loadMonthlyVisitors = async () => {
        const res = await getStatisticsMonthlyVisitors();
        if (res.status === 200) {
            setVisitorsData([{
                data: res.data.result.map(item => item.count)
            }]);
        }
    }
    const loadTransactionsInfo = async () => {
        const res = await getStatisticsTransactions();
        if (res.status === 200) {
            setBuyingData([
                res.data.payment, res.data.withdrawal, res.data.invest
            ]);
        }
    }
    const getBuyingPercent = value => {
        let total = buyingData[0] + buyingData[1] + buyingData[2];
        if (total > 0) {
            return (value / total * 100).toFixed(0);
        }
        return 0;
    }
    const loadCountriesInfo = async () => {
        const res = await getStatisticsCountriesInfo();
        if (res.status === 200) {
            setCustomersByCountry(res.data);
        }
    }

    useEffect(() => {
        if (customersByCountry.length > 0) {
            setSelectedCountryInfo(customersByCountry[0]);
        } else {
            setSelectedCountryInfo({
                country: "", count: 0, amount: 0
            });
        }
        console.log(customersByCountry);
    }, [customersByCountry]);


    const handleRegionClick = (event, code) => {
        console.log(code, customersByCountry);
        const item = customersByCountry.find(item => item.countryCode == code);
        if (item) {
            setSelectedCountryInfo(item);
        } else {
            setSelectedCountryInfo({
                country: code, count: 0, amount: 0
            });
        }
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Statistics
                    </div>
                </div>
                <div className="w-[180px] hidden">
                    <DateSinglePicker
                        date={selDate}
                        setDate={date => setSelDate(date)}
                    />
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="w-full bg-white p-6 rounded-[8px]">
                    <div className="flex flex-col md:flex-row gap-2 justify-between items-center">
                        <p className="text-black text-[18px] w-full font-[600]">Total Investment</p>
                    </div>
                    <div className="w-full overflow-x-auto">
                        <div className="w-full min-w-[600px]">
                            <ReactApexChart
                                options={barChartOptions}
                                series={totalInvestments}
                                type="bar"
                                width="100%"
                                height="280px"
                            />
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col md:flex-row gap-6">
                    <div className="w-full bg-white p-6 rounded-[8px]">
                        <div className="flex flex-col md:flex-row gap-2 justify-between items-center">
                            <p className="text-black text-[18px] w-full font-[600]">Sales Statistics</p>
                        </div>
                        <div className="w-full overflow-x-auto">
                            <div className="w-full min-w-[600px] md:min-w-full">
                                <ReactApexChart
                                    options={lineChartOptions}
                                    series={salesData}
                                    type="area"
                                    width="100%"
                                    height="280px"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full bg-white p-6 rounded-[8px]">
                        <div className="flex flex-col md:flex-row gap-2 justify-between items-center">
                            <p className="text-black text-[18px] w-full font-[600]">Total Users</p>
                        </div>
                        <div className="w-full overflow-x-auto">
                            <div className="w-full min-w-[600px] md:min-w-full">
                                <ReactApexChart
                                    options={lineChartOptions2}
                                    series={usersData}
                                    type="area"
                                    width="100%"
                                    height="280px"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col md:flex-row gap-6">
                    <div className="w-full bg-white p-6 rounded-[8px]">
                        <div className="flex flex-col md:flex-row gap-2 justify-between items-center mb-5">
                            <p className="text-black text-[18px] w-full font-[600]">Visitor By Country</p>
                        </div>
                        <div className="w-full h-[300px] flex flex-row justify-between items-center">
                            <div className="w-[65%] h-full" >
                                <VectorMap
                                    map={worldMill}
                                    regionsSelectable={true}
                                    backgroundColor="transparent"
                                    zoomOnScroll={false}
                                    containerStyle={{
                                        width: "100%",
                                        height: "100%"
                                    }}
                                    containerClassName="map"
                                    regionStyle={regionStyle}
                                    onRegionClick={handleRegionClick}
                                />
                            </div>
                            <div className="w-[25%] h-full flex flex-col items-start pt-10">
                                <div className="flex flex-col items-start gap-[2px]">
                                    <p className="text-black text-[14px] font-semibold">{selectedCountryInfo.country}</p>
                                    <p className="text-[10px] font-semibold text-[#717171]">{selectedCountryInfo.count} Visitors</p>
                                </div>
                                <div className="flex flex-col items-start gap-[2px] mt-[18px]">
                                    <p className="text-black text-[14px] font-semibold">{dollarFormatter(selectedCountryInfo.amount)}</p>
                                    <p className="text-[10px] font-semibold text-[#717171]">Sales total</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full bg-white p-6 rounded-[8px]">
                        <div className="flex flex-col md:flex-row gap-2 justify-between items-center">
                            <p className="text-black text-[18px] w-full font-[600]">Categories of Transactions</p>
                        </div>
                        <div className="w-full h-4 flex justify-between gap-2 mt-5">
                            <div className={`h-4 bg-[#FCBF07] rounded-lg`} style={{ width: `${getBuyingPercent(buyingData[0])}%` }}></div>
                            <div className={`h-4 bg-[#78C9F9] rounded-lg`} style={{ width: `${getBuyingPercent(buyingData[1])}%` }}></div>
                            <div className={`h-4 bg-[#AA57E8] rounded-lg`} style={{ width: `${getBuyingPercent(buyingData[2])}%` }}></div>
                        </div>
                        <div className="flex justify-between w-full items-center mt-4">
                            <div className="flex flex-col items-start">
                                <div className="flex items-center gap-1 justify-start ">
                                    <div className="w-2 h-2 rounded-[8px] bg-[#FCBF07]" />
                                    <p className="text-gray6 text-[12px] font-semibold">Payment</p>
                                </div>
                                <p className="text-[10px] font-semibold text-[#717171] ml-3">{getBuyingPercent(buyingData[0])} %</p>
                            </div>
                            <div className="flex flex-col items-start">
                                <div className="flex items-center gap-1 justify-start ">
                                    <div className="w-2 h-2 rounded-[8px] bg-[#78C9F9]" />
                                    <p className="text-gray6 text-[12px] font-semibold">Withdrawal</p>
                                </div>
                                <p className="text-[10px] font-semibold text-[#717171] ml-3">{getBuyingPercent(buyingData[1])} %</p>
                            </div>
                            <div className="flex flex-col items-start">
                                <div className="flex items-center gap-1 justify-start ">
                                    <div className="w-2 h-2 rounded-[8px] bg-[#AA57E8]" />
                                    <p className="text-gray6 text-[12px] font-semibold">Invest</p>
                                </div>
                                <p className="text-[10px] font-semibold text-[#717171] ml-3">{getBuyingPercent(buyingData[2])} %</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <ReactApexChart
                                options={pieChartOptions}
                                series={buyingData}
                                type="donut"
                                width="100%"
                                height="280px"
                            />
                        </div>
                    </div>
                </div>

                <div className="w-full bg-white p-6 rounded-[8px]">
                    <div className="flex flex-col md:flex-row gap-2 justify-between items-center">
                        <p className="text-black text-[18px] w-full font-[600]">Site Visitors</p>
                    </div>
                    <div className="w-full overflow-x-auto">
                        <div className="w-full min-w-[600px]">
                            <ReactApexChart
                                options={visitorOptions}
                                series={visitorsData}
                                type="bar"
                                width="100%"
                                height="280px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Statistics;