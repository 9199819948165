// @ts-nocheck

import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Input from "../../components/Form/Input";
import IconButton from "../../components/Buttons/IconButton";
import { Checkbox } from "@material-tailwind/react";
import DefaultSelect from "../../components/Form/DefaultSelect";
import { AdminRoles } from "../../utils/contants";
import { isValidateEmail } from "../../utils/utils";
import { toast } from "react-toastify";
import { addOrUpdateAdmin } from "../../services/axios";
import { hintEmails, hintFirstNames, validateEmail, validateFirstName } from "../../utils/validates";

const RoleEdit = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [adminInfo, setAdminInfo] = useState({
        role: "User"
    });

    const [nameValid, setNameValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);

    useEffect(() => {
        if (location.state) {
            setAdminInfo({
                ...location.state,
                password: null
            });
            setNameValid(true);
            setEmailValid(true);
        }
    }, [location]);

    const handleChange = (name, value) => {
        setAdminInfo(prev => ({
            ...adminInfo,
            [name]: value
        }));
    }

    const handleSubmit = async () => {
        if (!nameValid || !emailValid) {
            return toast.error("Parameters Invalid");
        }
        const res = await addOrUpdateAdmin(adminInfo);
        if (res.status === 200) {
            toast.success("Succeed!");
            navigate("/roles");
        } else {
            toast.error(res.data.message);
        }
    }


    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        {adminInfo ? "Edit Administrator" : "Add Administrator"}
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                    </div>
                </div>
            </div>

            <div className="w-full max-w-[680px] flex flex-col justify-evenly items-start self-start px-[32px] py-[24px] gap-6">
                <div className="w-full flex flex-col md:flex-row justify-between gap-6">
                    <Input label="First Name" placeholder="Enter your first name"
                        required={true}
                        value={adminInfo?.fname}
                        setValue={(value) => handleChange("fname", value)}
                        checkValid={validateFirstName}
                        hint={hintFirstNames}
                        isValid={setNameValid}
                    />
                    <Input label="Last Name" placeholder="Enter your last name"
                        value={adminInfo?.lname}
                        setValue={(value) => handleChange("lname", value)}
                    />
                </div>
                <div className="w-full">
                    <Input label="Email Address" placeholder="example@gmail.com"
                        required={true}
                        value={adminInfo?.email}
                        setValue={(value) => handleChange("email", value)}
                        checkValid={validateEmail}
                        hint={hintEmails}
                        isValid={setEmailValid}
                    />
                </div>
                <div className="w-full">
                    <Input label="Password" placeholder="Password"
                        required={true}
                        value={adminInfo?.password}
                        setValue={(value) => handleChange("password", value)}
                        type="password"
                    />
                </div>
                <div className="w-full mb-4">
                    <DefaultSelect
                        label="Select team member roles"
                        options={Object.values(AdminRoles)}
                        value={adminInfo?.role}
                        setValue={(value) => handleChange("role", value)}
                    />
                </div>

                <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                    <div className="w-full flex justify-between items-center">
                        <p className="text-black text-[18px] font-[600]">Role</p>
                    </div>
                    <div className="flex flex-col text-[14px]">
                        <p className="text-black text-[16px] font-[500]">{AdminRoles.SuperAdmin}</p>
                        <Checkbox color="green" label="All pages" checked={adminInfo?.role === AdminRoles.SuperAdmin} onChange={() => { }} />
                    </div>
                    <div className="flex flex-col text-[14px]">
                        <p className="text-black text-[16px] font-[500]">{AdminRoles.Admin}</p>
                        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-2">
                            <Checkbox color="green" label="Overview" checked={adminInfo?.role === AdminRoles.Admin} onChange={() => { }} />
                            <Checkbox color="green" label="Statistics" checked={adminInfo?.role === AdminRoles.Admin} onChange={() => { }} />
                            <Checkbox color="green" label="Client History" checked={adminInfo?.role === AdminRoles.Admin} onChange={() => { }} />
                            <Checkbox color="green" label="Tokenized Cars" checked={adminInfo?.role === AdminRoles.Admin} onChange={() => { }} />
                            <Checkbox color="green" label="Tokenized Projects" checked={adminInfo?.role === AdminRoles.Admin} onChange={() => { }} />
                            <Checkbox color="green" label="All Transactions" checked={adminInfo?.role === AdminRoles.Admin} onChange={() => { }} />
                            <Checkbox color="green" label="CMS" checked={adminInfo?.role === AdminRoles.Admin} onChange={() => { }} />
                            <Checkbox color="green" label="Contracts" checked={adminInfo?.role === AdminRoles.Admin} onChange={() => { }} />
                            <Checkbox color="green" label="Notification" checked={adminInfo?.role === AdminRoles.Admin} onChange={() => { }} />
                        </div>
                    </div>
                    <div className="flex flex-col text-[14px]">
                        <p className="text-black text-[16px] font-[500]">{AdminRoles.User}</p>
                        <Checkbox color="green" label="Statistics" checked={adminInfo?.role === AdminRoles.User} onChange={() => { }} />
                    </div>
                </div>
                <IconButton text="Save" TxSize="text-[14px]" py="py-[10px]" className="w-full md:w-[120px]" onClick={handleSubmit} />
            </div>
        </div>
    );
};

export default RoleEdit;