// @ts-nocheck

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";
import { Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";

import FilterIcon from "../../assets/icons/filter.svg";
import PlusIcon from "../../assets/icons/plus.svg";
import { FaEllipsisV } from "react-icons/fa";
import Pagination from "../../components/Pagination";
import SearchInput from "../../components/Form/SearchInput";
import IconButton from "../../components/Buttons/IconButton";
import { deleteAdmin, getAdmins } from "../../services/axios";
import { DATETIME_FORMAT } from "../../utils/contants";
import { showConfirmBox } from "../../utils/utils";
import { toast } from "react-toastify";


const Admins = () => {

    const navigate = useNavigate();

    const [dataList, setDataList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        loadPage();
    }, []);

    const loadPage = async (page = 1) => {
        const res = await getAdmins({
            page, pageSize, keyword
        });
        if (res.status === 200) {
            setDataList(res.data.items);
            setTotalCount(res.data.totalCount);
        }
        setCurPage(page);
    }

    const handleAdd = () => {
        navigate("/roles/edit");
    }

    const handleEdit = (item: any) => {
        navigate("/roles/edit", {
            state: item
        });
    }

    const handleRevoke = (item: any) => {

    }

    const handleRemove = (item: any) => {
        showConfirmBox("Warning", "Are you sure to delete?",
            async () => {
                const res = await deleteAdmin(item._id);
                if (res.status === 200) {
                    toast.success("Successfully deleted!");
                    loadPage(curPage);
                } else {
                    toast.error(res.data.message);
                }
            });
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Two factor authentications required for your team
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="w-full flex flex-col md:flex-row justify-between items-center mb-[16px] gap-3">
                    <SearchInput
                        bgClass="bg-white" widthClass="w-full md:w-[300px]" className='border border-border2' placeholder="Search"
                        value={keyword} setValue={setKeyword} onPressEnter={loadPage}
                    />
                    <IconButton icon={PlusIcon} text="Add Admin" TxSize="text-[14px]" py="py-[10px]" px="px-[10px]" className="w-full md:w-[200px]" onClick={handleAdd} />
                </div>
                <div className="flex flex-col w-full overflow-x-auto pt-[16px] gap-3">
                    <div className="w-full min-w-fit flex items-center justify-between gap-1 p-6 bg-white rounded-[8px]">
                        <p className="w-[25%] min-w-[200px] text-black text-[16px] font-[600]">Member</p>
                        <p className="w-[20%] min-w-[150px] text-black text-[16px] font-[600]">Roles</p>
                        <p className="w-[20%] min-w-[150px] text-black text-[16px] font-[600]">Authorisation</p>
                        <p className="w-[20%] min-w-[150px] text-black text-[16px] font-[600]">Last Connection</p>
                        <p className="w-[5%] min-w-[50px] text-black text-[16px] font-[600] text-end"></p>
                    </div>
                    {dataList.map((item, index) =>
                        <div key={index} className="w-full min-w-fit flex items-center justify-between p-6 gap-1 bg-white rounded-[8px]">
                            <div className="w-[25%] min-w-[200px]">
                                <p className="text-[14px] font-[500] text-black">{item.fname} {item.lname}</p>
                                <p className="text-[14px] font-[500] text-gray6">{item.email}</p>
                            </div>
                            <div className="w-[20%] min-w-[150px]">
                                <p className="text-[16px] font-[500] text-black">{item.role}</p>
                                {item.role2 &&
                                    <p className="text-[14px] font-[500] text-gray6">{item.role2}</p>
                                }
                            </div>
                            <div className="w-[20%] min-w-[150px]">
                                {item.otpEnabled &&
                                    <span className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[12px] text-[#55BA68] text-center cursor-pointer">
                                        Two Factor
                                    </span>
                                }
                            </div>
                            <div className="w-[20%] min-w-[150px]">
                                <p className="text-[14px] font-[500] text-gray6">
                                    {item.lastLoginAt && dayjs(item.lastLoginAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}
                                </p>
                            </div>
                            <div className="w-[5%] min-w-[50px] flex justify-end">
                                <Menu placement="bottom-end">
                                    <MenuHandler>
                                        <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                    </MenuHandler>
                                    <MenuList>
                                        <MenuItem className="text-black text-[14px]" onClick={() => handleEdit(item)}>Edit</MenuItem>
                                        <MenuItem className="text-black text-[14px]" onClick={() => handleRevoke(item)}>Revoke</MenuItem>
                                        <MenuItem className="text-[#FF5C5C] text-[14px]" onClick={() => handleRemove(item)}>Delete</MenuItem>
                                    </MenuList>
                                </Menu>
                            </div>
                        </div>
                    )}
                </div>

                <div>
                    <Pagination
                        totalCount={totalCount}
                        onGoToPage={(page) => loadPage(page)}
                        pageSize={pageSize}
                    />
                </div>
            </div>
        </div>
    );
};

export default Admins;