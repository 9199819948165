//@ts-nocheck
import { Link, useLocation } from "react-router-dom"
import { useEffect, useState } from "react";

import LogoImg from "../assets/image/logo.png"
import LogoutIcon from "../assets/icons/logout.svg"
import NavItem from "./NavItem";
import { navitemList } from "../utils/contants";
import IconButton from "../components/Buttons/IconButton";
import { useAuth } from "../hooks/useAuth";

export default function Sidebar(props: any) {

    const location = useLocation();
    const { user, logout } = useAuth();

    const [active, setActive] = useState(0);
    const [childActive, setChildActive] = useState(0);

    useEffect(() => {
        let pathname = location.pathname;
        navitemList.forEach((item) => {
            if (pathname.startsWith(item.path)) {
                setActive(item.active);
                if (item.childItems?.length > 0) {
                    item.childItems.forEach(child => {
                        if (pathname.startsWith(child.path)) {
                            setChildActive(child.active);
                        }
                    });
                } else {
                    setChildActive(0);
                }
            }
        });
    }, [location]);


    const handleLogout = () => {
        logout();
    }

    return (
        <>
            {props.mobileView && (
                <>
                    <div
                        className={`fixed top-0 left-0 w-screen h-screen z-40 ${props.show ? "block" : "hidden"}`}
                        onClick={() => { props.setShow(false); }}
                    ></div>
                    <div className={`fixed z-50 top-[75px] right-[40px] ${props.show ? "block" : "hidden"}
                        w-0 h-0 border-l-[10px] border-l-transparent border-b-[17px] border-b-white border-r-[10px] border-r-transparent`}></div>
                </>
            )}
            <div
                className={`bg-white w-[248px] pb-8 px-[12px] z-10 ${props.mobileView
                    ? "fixed h-[calc(100vh-120px)] overflow-y-scroll overflow-x-hidden  z-50 transition-opacity duration-1000 top-[92px] right-[10px] rounded-[8px]"
                    : "relative flex flex-col justify-between"
                    } ${props.show ? "block" : "hidden"}`}
            >
                <div>
                    {!props.mobileView &&
                        <Link
                            className="flex items-center gap-x-2 pl-[20px] pt-[21px]"
                            to={"/"}
                            target="_self"
                        >
                            <img src={LogoImg} alt="logo" className="w-[30px] h-[29px]" />
                            <p className="text-black5 text-[18px] font-[800] font-Urbanist-Bold">Hulk Cars</p>
                        </Link>
                    }

                    <div className="mt-[21px]">
                        <div className="flex flex-col gap-y-1">
                            {navitemList.map((item, index) => {
                                if (item.roles.includes(user?.role)) {
                                    return (
                                        <NavItem
                                            key={index}
                                            text={item.text}
                                            img={item.img}
                                            active_img={item.active_img}
                                            active={active === item.active && true}
                                            path={item.path}
                                            childItems={item.childItems}
                                            childActive={childActive}
                                        />
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                        </div>
                    </div>
                </div>

                <div className="mt-10 flex justify-center">
                    <IconButton text="Log Out" className="w-[180px]" icon={LogoutIcon} onClick={handleLogout} />
                </div>
            </div>
        </>
    )
}