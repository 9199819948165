import { isValidateEmail } from "./utils";


export const hintFirstNames = ["", "First Name required", "First Name is incorrect"];
export const hintEmails = ["", "Email Address required", "Email Address is incorrect"];

export const hintDocumentNames = ["", "Document name required"];

export const hintCarNames = ["", "Car Name/Model required"];
export const hintAmountFunded = ["", "Amount required", "Amount is incorrect"];
export const hintProjectNames = ["", "Project Name required"];

export const hintTitle = ["", "Title required"];

export const validateFirstName = (str: any) => {
    if (str === "") return 1;
    else if (str.length <= 2) return 2;
    else return 0;
}
export const validateEmail = (str: any) => {
    if (str === "") return 1;
    else if (!isValidateEmail(str)) return 2;
    else return 0;
}


export const validateDocumentName = (str: any) => {
    if (str === "") return 1;
    else return 0;
}

export const validateCarName = (str: any) => {
    if (str === "") return 1;
    else return 0;
}

export const validateProjectName = (str: any) => {
    if (str === "") return 1;
    else return 0;
}

export const validateAmountFunded = (str: any) => {
    if (str === "") return 1;
    else if (Number(str) < 0) return 2;
    else return 0;
}


export const validateTitle = (str: any) => {
    if (str === "") return 1;
    else return 0;
}
