
import { Outlet } from "react-router-dom"
import { useScrollRestoration } from "../hooks/useScrollRestoration";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../hooks/useAuth";

export default function MainLayout() {
    const { user, logout } = useAuth();
    useScrollRestoration();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })
    const [sideBarShow, setSideBarShow] = useState(false);

    useEffect(() => {
        if (!user) {
            logout();
        }
    }, [user]);

    return (
        <>
            <div className="flex bg-mainback">
                <Sidebar
                    mobileView={isTabletOrMobile}
                    show={isTabletOrMobile ? sideBarShow : true}
                    setShow={setSideBarShow} />
                <div className="w-full flex-1 flex flex-col">
                    <Header onMenuClick={() => setSideBarShow(!sideBarShow)} />
                    <div className="w-full lg:w-[calc(100vw-252px)] min-h-[calc(100vh-72px)] font-Urbanist">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}