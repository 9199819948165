// @ts-nocheck

import React, { useEffect, useMemo } from 'react';
import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { useLocalStorage } from "./useLocalStorage";
import { api, setAuthorization, unsetAuthorization } from '../services/api';


export interface AuthFunction {
  user: any,
  login(data: any): void;
  logout(): void;
  updateUser(data: any): void;
}

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    setUser(data);
    setAuthorization(data.token);
    navigate("/");
  };

  const updateUser = async (data: any) => {
    setUser(data);
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    unsetAuthorization();
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      updateUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  useEffect(() => {
    if (user && user.token) {
      setAuthorization(user.token);
    }
  }, [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = (): AuthFunction => {
  return useContext(AuthContext);
};