// @ts-nocheck

import React, { useState, useEffect } from "react";

import Input from "../../components/Form/Input";
import Textarea from "../../components/Form/Textarea";
import IconButton from "../../components/Buttons/IconButton";
import { Checkbox } from "@material-tailwind/react";
import DateSinglePicker from "../../components/Form/DatePicker";
import DefaultSelect from "../../components/Form/DefaultSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { hintTitle, validateTitle } from "../../utils/validates";
import { toast } from "react-toastify";
import { addCMSItem, updateCMSItem } from "../../services/axios";

const _QA = { question: "", answer: "" };
const FaqEdit = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);
    const [faqInfo, setFaqInfo] = useState({
        QAs: [{ ..._QA }]
    });

    const [titleValid, setTitleValid] = useState(false);

    useEffect(() => {
        if (location.state) {
            setIsEdit(true);
            setFaqInfo(location.state);
        }
    }, [location]);

    const handleChange = (name, value) => {
        setFaqInfo(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleChangeQA = (index, name, value) => {
        let QA = {
            ...(faqInfo.QAs[index]),
            [name]: value
        };
        let QAs = faqInfo.QAs.map((item, idx) => index == idx ? QA : item);
        setFaqInfo(prev => ({
            ...prev,
            QAs: QAs
        }));
    }

    const handleAddSection = () => {
        setFaqInfo(prev => ({
            ...prev,
            QAs: [...(prev.QAs), { ..._QA }]
        }));
    }

    const handleSubmit = async () => {
        if (!titleValid) {
            return toast.error("Values are incorrect!");
        }

        const res = isEdit ? await updateCMSItem(faqInfo._id, 'faq', faqInfo) : await addCMSItem('faq', faqInfo);
        if (res.status === 200) {
            toast.success("Successfully saved!");
            navigate("/cms/faq");
        } else {
            toast.error(res.data.message);
        }
    }


    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        {faqInfo ? "Edit FAQ" : "Create FAQ"}
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                    </div>
                </div>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6 px-[32px] py-[24px]">
                <div className="md:col-span-2 w-full flex flex-col justify-start items-center gap-6">
                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">FAQ Title</p>
                        </div>
                        <Input
                            value={faqInfo?.title}
                            secondaryMsg="At least 100 characters. Special characters are not allowed. (e.g. @, /, -, *, #)"
                            setValue={(value) => handleChange("title", value)}
                            required={true}
                            checkValid={validateTitle}
                            hint={hintTitle}
                            isValid={setTitleValid}
                        />
                    </div>

                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">Add Question And Answer</p>
                        </div>
                        {faqInfo?.QAs.map((item, index) => (
                            <div key={index} className="w-full border border-border2 rounded-lg p-4">
                                <Input
                                    label={`Question ${index + 1}`}
                                    value={faqInfo?.QAs[index]?.question}
                                    setValue={(value) => handleChangeQA(index, "question", value)}
                                />
                                <Textarea
                                    label={`Answer ${index + 1}`}
                                    rows={3}
                                    value={faqInfo?.QAs[index]?.answer}
                                    setValue={(value) => handleChangeQA(index, "answer", value)}
                                />
                            </div>
                        ))}


                        <div className="text-[#48B825] text-[12px] font-[600] pl-3">
                            <span className="text-[16px] me-2">+</span>
                            <span className="cursor-pointer" onClick={handleAddSection}>Add New Section</span>
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col justify-start items-center gap-6">
                    <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5 order-last md:order-first">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-black text-[18px] font-[600]">Publish</p>
                        </div>
                        <DefaultSelect
                            label="Status"
                            options={[
                                { value: 0, label: "Pending" },
                                { value: 1, label: "Published" },
                            ]}
                            value={faqInfo?.publishStatus}
                            setValue={(value) => handleChange("publishStatus", value)}
                        />
                        <DefaultSelect
                            label="Visibility"
                            options={[
                                { value: 0, label: "Private" },
                                { value: 1, label: "Public" },
                            ]}
                            value={faqInfo?.visibility}
                            setValue={(value) => handleChange("visibility", value)}
                        />
                        <DateSinglePicker
                            label="Published on"
                            date={faqInfo?.publishedAt}
                            setDate={(value) => handleChange("publishedAt", value)}
                        />
                        <IconButton
                            text="Publish" TxSize="text-[14px]" py="py-[10px]" className="w-full md:w-[120px]"
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqEdit;