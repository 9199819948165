// @ts-nocheck

import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Input from "../../components/Form/Input";
import DefaultSelect from "../../components/Form/DefaultSelect";
import Textarea from "../../components/Form/Textarea";
import IconButton from "../../components/Buttons/IconButton";
import { Checkbox, Dialog, DialogBody } from "@material-tailwind/react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DateSinglePicker from "../../components/Form/DatePicker";
import ImageDropZone from "../../components/Form/ImageDropZone";
import { addCategory, addCMSItem, deleteCategory, getCategories, updateCMSItem } from "../../services/axios";
import { toast } from "react-toastify";
import { hintTitle, validateTitle } from "../../utils/validates";
import { convertToRaw, ContentState } from 'draft-js';
import { FaRegSquareMinus, FaCircleMinus } from 'react-icons/fa6'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { showConfirmBox } from "../../utils/utils";

const BlogEdit = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");

    const [isEdit, setIsEdit] = useState(false);
    const [blogInfo, setBlogInfo] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [titleValid, setTitleValid] = useState(false);

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        if (location.state) {
            setIsEdit(true);
            setBlogInfo(location.state);

            const contentBlock = htmlToDraft(location.state.description);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                setEditorState(EditorState.createWithContent(contentState));
            }
        }
    }, [location]);

    const handleEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const handleChange = (name, value) => {
        setBlogInfo(prev => ({
            ...prev,
            [name]: value
        }));
    }


    const loadCategories = async () => {
        const res = await getCategories();
        if (res.status === 200) {
            setCategories(res.data.result);
        }
    }
    const handleAddCategory = async () => {
        if (newCategoryName.length === 0) {
            return toast.error("Please input the category name!");
        }
        const isExist = categories.findIndex(c => c.name === newCategoryName);
        if (isExist !== -1) {
            return toast.error("Category is already exist!");
        }
        const res = await addCategory(newCategoryName);
        if (res.status === 200) {
            loadCategories();
            setIsModalVisible(false);
        } else {
            return toast.error(res.data.message);
        }
    }
    const handleChangeCategory = (name, checked) => {
        let newCategories = blogInfo?.categories ? [...blogInfo?.categories] : [];
        if (checked) {
            newCategories = [...newCategories, name];
        } else {
            newCategories = newCategories.filter(c => c !== name);
        }
        setBlogInfo(prev => ({
            ...prev,
            "categories": newCategories
        }));
    }
    const handleDeleteCategory = (item) => {
        showConfirmBox("Warning", "Are you sure to delete?",
            async () => {
                const res = await deleteCategory(item._id);
                if (res.status === 200) {
                    loadCategories();
                } else {
                    return toast.error(res.data.message);
                }
            });
    }

    const handleAcceptFile = (files) => {
        setBlogInfo(prev => ({
            ...prev,
            image: files[0],
        }));
    }

    const handleSubmit = async () => {
        if (!titleValid) {
            return toast.error("Values are incorrect!");
        }
        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (description.length === 0) {
            return toast.error("Description is empty!");
        }

        const res = isEdit ? await updateCMSItem(blogInfo._id, 'blog', { ...blogInfo, description }) : await addCMSItem('blog', { ...blogInfo, description });
        if (res.status === 200) {
            toast.success("Successfully saved!");
            navigate("/cms/blog");
        } else {
            toast.error(res.data.message);
        }
    }


    return (
        <>
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                    <div>
                        <div className="text-black text-[20px] font-Urbanist-Bold">
                            {isEdit ? "Edit Blog" : "Add Blogs"}
                        </div>
                        <div className="text-gray6 text-[12px] font-[600]">
                        </div>
                    </div>
                </div>

                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6 px-[32px] py-[24px]">
                    <div className="md:col-span-2 w-full flex flex-col justify-start items-center gap-6">
                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">Blog Content</p>
                            </div>
                            <Input
                                label="Blog Title"
                                value={blogInfo?.title}
                                secondaryMsg="At least 100 characters. Special characters are not allowed. (e.g. @, /, -, *, #)"
                                setValue={(value) => handleChange("title", value)}
                                required={true}
                                checkValid={validateTitle}
                                hint={hintTitle}
                                isValid={setTitleValid}
                            />
                            <div className="w-full flex flex-col gap-2 relative">
                                <p className="text-black text-[16px] font-[500]">Description</p>
                                <div>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="rounded-[4px]"
                                        wrapperClassName=""
                                        editorClassName="border border-border2 px-2 min-h-[300px] max-h-[600px] rounded-[4px]"
                                        onEditorStateChange={handleEditorStateChange}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'textAlign', 'list', 'colorPicker', 'link'],
                                            inline: {
                                                options: ['bold', 'italic', 'underline', 'strikethrough']
                                            }
                                        }}
                                    />
                                </div>
                                <p className="text-[12px] text-[#9B9DA5]">Hover on edit icon to format text.</p>
                            </div>
                        </div>

                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">Featured Image</p>
                            </div>
                            <ImageDropZone
                                kind="cms"
                                type="image"
                                maxFiles={1}
                                files={blogInfo?.image ? [blogInfo?.image] : []}
                                onAcceptFiles={(files) => handleAcceptFile(files)}
                            />
                        </div>

                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">Excerpt</p>
                            </div>
                            <Textarea
                                rows={5}
                                value={blogInfo?.excerpt}
                                setValue={(value) => handleChange("excerpt", value)}
                            />
                        </div>

                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">Slug</p>
                            </div>
                            <Input
                                value={blogInfo?.slug}
                                setValue={(value) => handleChange("slug", value)}
                            />
                        </div>

                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">Author</p>
                            </div>
                            <Input
                                value={blogInfo?.author}
                                setValue={(value) => handleChange("author", value)}
                            />
                        </div>

                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">SEO</p>
                            </div>
                            <Input
                                label="Blog Title"
                                value={blogInfo?.seoTitle}
                                setValue={(value) => handleChange("seoTitle", value)}
                            />
                            <Input
                                label="Meta Keywords"
                                value={blogInfo?.seoKeywords}
                                setValue={(value) => handleChange("seoKeywords", value)}
                            />
                            <Textarea
                                rows={5}
                                label="Meta Descriptions"
                                value={blogInfo?.seoDescription}
                                setValue={(value) => handleChange("seoDescription", value)}
                            />
                        </div>
                    </div>

                    <div className="w-full flex flex-col justify-start items-center gap-6">
                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5 order-last md:order-first">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">Publish</p>
                            </div>
                            <DefaultSelect
                                label="Status"
                                options={[
                                    { value: 0, label: "Pending" },
                                    { value: 1, label: "Published" },
                                ]}
                                value={blogInfo?.publishStatus}
                                setValue={(value) => handleChange("publishStatus", value)}
                            />
                            <DefaultSelect
                                label="Visibility"
                                options={[
                                    { value: 0, label: "Private" },
                                    { value: 1, label: "Public" },
                                ]}
                                value={blogInfo?.visibility}
                                setValue={(value) => handleChange("visibility", value)}
                            />
                            <DateSinglePicker
                                label="Published on"
                                date={blogInfo?.publishedAt}
                                setDate={(value) => handleChange("publishedAt", value)}
                            />
                            <IconButton
                                text="Publish"
                                TxSize="text-[14px]" py="py-[10px]" className="w-full md:w-[120px]"
                                onClick={handleSubmit}
                            />
                        </div>

                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">Categories</p>
                            </div>
                            <div className="flex flex-col">
                                {categories.map(item => (
                                    <div className="flex justify-between items-center">
                                        <Checkbox
                                            key={item._id} label={item.name} color="green"
                                            checked={blogInfo?.categories?.includes(item.name)}
                                            onChange={(e) => handleChangeCategory(item.name, e.target.checked)}
                                        />
                                        <FaCircleMinus className="text-[#ff0000] cursor-pointer" size='20' onClick={e => handleDeleteCategory(item)} />
                                    </div>
                                ))}
                            </div>
                            <div className="text-[#48B825] text-[12px] font-[600] pl-3 cursor-pointer" onClick={() => setIsModalVisible(true)}>
                                <span className="text-[16px] me-2">+</span><span>Add New Category</span>
                            </div>
                        </div>

                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">Video URL</p>
                            </div>
                            <Input
                                placeholder="URL"
                                secondaryMsg="YouTube Embed URL"
                                value={blogInfo?.videoUrl}
                                setValue={(value) => handleChange("videoUrl", value)}
                            />
                        </div>

                        <div className="w-full flex flex-col bg-white p-8 rounded-[8px] gap-5">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-black text-[18px] font-[600]">Tags</p>
                            </div>
                            <Input
                                secondaryMsg="Use comma to separate the tags"
                                value={blogInfo?.tags}
                                setValue={(value) => handleChange("tags", value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={isModalVisible} size="xs" handler={() => setIsModalVisible(false)}>
                <DialogBody className="p-8 flex flex-col items-center justify-evenly gap-4 overflow-y-scroll max-h-[calc(100vh-20px)]">
                    <div className="mb-4">
                        <p className="text-black text-[20px] font-[600] text-center">Add New Category</p>
                        <p className="text-gray6 text-[12px] font-[600]"> </p>
                    </div>
                    <Input label="Category Name" value={newCategoryName} setValue={setNewCategoryName} />

                    <IconButton
                        text="Save" TxSize="text-[14px]" py="py-[10px]" className="w-full mt-4"
                        onClick={handleAddCategory}
                    />
                </DialogBody>
            </Dialog>
        </>
    );
};

export default BlogEdit;