// @ts-nocheck

import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ReactApexChart from "react-apexcharts";

import { dollarFormatter, getBlockchainHashLink, getRisingPercent, getRisingValue } from "../../utils/utils";
import { Link } from "react-router-dom";

import DateSinglePicker from "../../components/Form/DatePicker";

import ActiveUserIcon from "../../assets/icons/overview/active-user.svg";
import TransactionIcon from "../../assets/icons/overview/recent-transaction.svg";
import InvestmentIcon from "../../assets/icons/overview/total-investment.svg";
import TokenizedIcon from "../../assets/icons/overview/tokenized-car.svg";

import UpRightIcon from "../../assets/icons/up-right.svg";
import DownLeftIcon from "../../assets/icons/down-left.svg";

import { getStatisticsMonthlyTransactions, getStatisticsMonthlyUsers, getStatisticsOverview, getTransactions } from "../../services/axios";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/contants";

const _overViewInfo = [
    {
        id: "active_users",
        title: "Active Users",
        value: 0,
        decimals: 0,
        icon:
            <div className="absolute w-6 h-6 top-6 right-6">
                <img src={ActiveUserIcon} alt="users" />
            </div>,
        isRising: true,
        risingPercent: 0,
        risingValue: 0,
    },
    {
        id: "recent_transactions",
        title: "Recent Transactions",
        value: 0,
        decimals: 0,
        icon:
            <div className="absolute w-6 h-6 top-6 right-6">
                <img src={TransactionIcon} alt="tx" />
            </div>,
        isRising: false,
        risingPercent: 0,
        risingValue: 0,
    },
    {
        id: "total_investment",
        title: "Total investment",
        prefix: "$",
        value: 0,
        decimals: 2,
        icon:
            <div className="absolute w-6 h-6 top-6 right-6">
                <img src={InvestmentIcon} alt="invest" />
            </div>,
        isRising: true,
        risingPercent: 0,
        risingValue: 0,
    },
    {
        id: "tokenized_car",
        title: "Tokenised Cars",
        value: 0,
        decimals: 0,
        icon:
            <div className="absolute w-6 h-6 top-6 right-6">
                <img src={TokenizedIcon} alt="car" />
            </div>,
        isRising: false,
        risingPercent: 0,
        risingValue: 0,
    }
]

const chartOptions = {
    chart: {
        toolbar: { show: false },
    },
    stroke: {
        curve: "smooth",
        width: 2
    },
    colors: ["#FBBF04", "#55BA68"],
    tooltip: {
        custom: (props: any) => {
            return '<div class="chart-tooltip-container bg-black px-2">' +
                '<span class="chart-tooltip-font text-white">' + (props.seriesIndex == 1 ? "$" : "") + props.series[props.seriesIndex][props.dataPointIndex] + '</span>' +
                '</div>'
        }
    },
    xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Aug", "Sep", "Oct", "Nov", "Dec"],
        labels: {
            style: {
                colors: "#7A7E82",
                fontSize: '14px',
            }
        },
        tooltip: false
    },
    yaxis: {
        // categories: [1, 2, 3, 4, 5, 6, 7, 8, 1, 5, 1, 65, 1],
        labels: {
            style: {
                colors: "#7A7E82"
            }
        },
    },
    legend: {
        show: false
    }
};

const _chartSeries = [
    {
        name: "User Growth",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
        name: "Transaction Volume",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
];

const pieChartOptions = {
    chart: {
        type: 'donut',
        dropShadow: {
            enabled: true,
            color: '#111',
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2
        }
    },
    colors: ['#3BD8D5', '#46A1E9', '#FFC63E', '#9F6EFB', '#F86E51', '#FFAB5F', '#2AAB5F', '#9F90FB', '#FFC6FE', '#F0363E', '#2FD63E', '#DFC43E'],
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    stroke: {
        width: 0,
    },
    plotOptions: {
        pie: {
            donut: {
                labels: {
                    show: true,
                    total: {
                        show: false,
                    },
                }
            }
        }
    },
    dataLabels: {
        enabled: false,
    },
    fill: {
        opacity: 1,
        pattern: {
            enabled: false,
        },
    },
    states: {
        hover: {
            filter: 'none'
        }
    },
    legend: {
        position: "top",
        enabled: false,
        show: true
    },
}
const _pieChartSeries = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


const Dashboard = () => {

    const [selDate, setSelDate] = useState(new Date());

    const [overviewData, setOverviewData] = useState(_overViewInfo);
    const [chartSeries, setChartSeries] = useState(_chartSeries);
    const [pieChartSeries, setPieChartSeries] = useState(_pieChartSeries);
    const [activityData, setActivityData] = useState([]);

    useEffect(() => {
        loadOverview(selDate);
    }, [selDate]);

    useEffect(() => {
        loadMonthlyUsersTxs();
        loadMonthlyInvests();
        loadRecentActivities();
    }, []);

    const loadOverview = async (selDate) => {
        const res = await getStatisticsOverview(selDate);
        if (res.status === 200) {
            let overviewInfo = [..._overViewInfo];

            overviewInfo[0].value = res.data.activeUserInfo.thisWeek;
            overviewInfo[0].isRising = res.data.activeUserInfo.thisWeek >= res.data.activeUserInfo.prevWeek;
            overviewInfo[0].risingPercent = getRisingPercent(res.data.activeUserInfo.thisWeek, res.data.activeUserInfo.prevWeek);
            overviewInfo[0].risingValue = getRisingValue(res.data.activeUserInfo.thisWeek, res.data.activeUserInfo.prevWeek);

            overviewInfo[1].value = res.data.transactionInfo.thisWeek;
            overviewInfo[1].isRising = res.data.transactionInfo.thisWeek >= res.data.transactionInfo.prevWeek;
            overviewInfo[1].risingPercent = getRisingPercent(res.data.transactionInfo.thisWeek, res.data.transactionInfo.prevWeek);
            overviewInfo[1].risingValue = getRisingValue(res.data.transactionInfo.thisWeek, res.data.transactionInfo.prevWeek);

            overviewInfo[2].value = res.data.investInfo.thisWeek;
            overviewInfo[2].isRising = res.data.investInfo.thisWeek >= res.data.investInfo.prevWeek;
            overviewInfo[2].risingPercent = getRisingPercent(res.data.investInfo.thisWeek, res.data.investInfo.prevWeek);
            overviewInfo[2].risingValue = getRisingValue(res.data.investInfo.thisWeek, res.data.investInfo.prevWeek);

            overviewInfo[3].value = res.data.carInfo.thisWeek;
            overviewInfo[3].isRising = res.data.carInfo.thisWeek >= res.data.carInfo.prevWeek;
            overviewInfo[3].risingPercent = getRisingPercent(res.data.carInfo.thisWeek, res.data.carInfo.prevWeek);
            overviewInfo[3].risingValue = getRisingValue(res.data.carInfo.thisWeek, res.data.carInfo.prevWeek);

            setOverviewData(overviewInfo);
        }
    }

    const loadMonthlyUsersTxs = async () => {
        let chartData = [..._chartSeries];

        const res = await getStatisticsMonthlyUsers();
        if (res.status === 200) {
            chartData[0].data = res.data.result.map(item => item.count);
        }

        const res1 = await getStatisticsMonthlyTransactions(null);
        if (res1.status === 200) {
            chartData[1].data = res1.data.result.map(item => item.amount);
        }

        setChartSeries(chartData);
    }

    const loadMonthlyInvests = async () => {
        const res = await getStatisticsMonthlyTransactions("invest");
        if (res.status === 200) {
            setPieChartSeries(res.data.result.map(item => item.amount));
        }
    }

    const loadRecentActivities = async () => {
        const res = await getTransactions({ page: 1, pageSize: 6 });
        if (res.status === 200) {
            setActivityData(res.data.items);
        }
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Welcome back, Jenny
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                        Here’s what's happening with your store today.
                    </div>
                </div>
                <div className="hidden md:block w-[180px]">
                    <DateSinglePicker
                        date={selDate}
                        setDate={date => setSelDate(date)}
                    />
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-5">
                    {
                        overviewData.map((item, index) =>
                            <div key={index} className="bg-white h-full p-6 relative select-none rounded-[8px]">
                                <p className="text-[16px] font-[600] text-gray6">{item.title}</p>
                                <p className="text-black text-[29px] font-semibold mt-2">{item.prefix ?? ''} <CountUp end={item.value} separator="," decimals={item.decimals} /></p>
                                <div className="flex items-center mt-6 gap-2">
                                    {item.isRising ?
                                        <img src={UpRightIcon} alt="up" className="w-4 h-4" />
                                        :
                                        <img src={DownLeftIcon} alt="down" className="w-4 h-4" />
                                    }
                                    <label className={`text-[14px] font-semibold ${item.isRising ? "text-[#55BA68]" : "text-[#F86E51]"}`}>{item.risingPercent}%</label>
                                    <label className="text-[14px] font-semibold text-gray6">{item.risingValue} this week</label>
                                </div>
                                {item.icon}
                            </div>
                        )
                    }
                </div>

                <div className="w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-5">
                    <div className="bg-white p-6 pb-1 rounded-[8px] 2xl:col-span-3">
                        <div className="flex flex-col md:flex-row gap-2 justify-between items-center mb-4">
                            <p className="text-black text-[18px] w-full font-[600]">Performance Charts</p>
                            <div className="flex md:justify-end w-full items-center">
                                <div className="w-2 h-2 rounded-full bg-[#FAC013]" />
                                <label className="text-[14px] font-[600] text-[#717171] ml-1">User Growth</label>

                                <div className="w-2 h-2 rounded-full bg-[#55BA68] ml-3" />
                                <label className="text-[14px] font-[600] text-[#717171] ml-1">Transaction Volume</label>
                            </div>
                        </div>
                        <div className="w-full overflow-x-auto">
                            <div className="w-full min-w-[600px]">
                                <ReactApexChart
                                    options={chartOptions}
                                    series={chartSeries}
                                    type="line"
                                    width="100%"
                                    height={260}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-6 pb-1 rounded-[8px] min-h-[350px]">
                        <div className="flex flex-col justify-between items-center">
                            <p className="text-black text-[18px] w-full font-[600]">Total Investment</p>
                        </div>
                        <ReactApexChart
                            options={pieChartOptions}
                            series={pieChartSeries}
                            type="donut"
                            width={'100%'}
                            height={'100%'}
                        />
                    </div>
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600]">Recent Activity</p>
                        <Link
                            to={"/transactions/all"}
                            className="bg-[#48B825] p-[7px_16px] rounded-[8px] w-[100px] text-center">
                            <p className="text-white text-[12px] font-semibold">See all</p>
                        </Link>
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="flex items-center justify-between pb-3">
                            <p className="w-[20%] min-w-[200px] text-black text-[14px] font-semibold">Order ID</p>
                            <p className="w-[25%] min-w-[200px] text-black text-[14px] font-semibold">Customer Name</p>
                            <p className="w-[20%] min-w-[150px] text-black text-[14px] font-semibold">Date</p>
                            <p className="w-[20%] min-w-[100px] text-black text-[14px] font-semibold">Amount</p>
                            <p className="w-[15%] min-w-[150px] text-black text-[14px] font-semibold">Tracking</p>
                        </div>
                        {activityData.map((activityitem, index) =>
                            <div key={index} className="flex w-full items-center justify-between py-3">
                                <div className="w-[20%] min-w-[200px]">
                                    <p className="text-[14px] font-[500] text-gray6">{activityitem._id}</p>
                                </div>
                                <div className="w-[25%] min-w-[200px] flex items-center gap-2">
                                    <p className="text-[14px] font-[500] text-gray6">{activityitem.user?.fullname}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-gray6">{dayjs(activityitem.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                </div>
                                <div className="w-[20%] min-w-[100px]">
                                    <p className="text-[14px] font-[500] text-gray6" >{dollarFormatter(activityitem.amount)}</p>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    {activityitem.status === 1 ?
                                        <a className="block p-[4px_12px] w-[118px] bg-[#55BA6833] rounded-[32px] text-[12px] text-[#55BA68] text-center cursor-pointer"
                                            href={getBlockchainHashLink(activityitem.txHash)} target="_blank" rel="noreferrer"
                                        >
                                            Completed
                                        </a> :
                                        <span className="block p-[4px_12px] w-[118px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                            Pending
                                        </span>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;