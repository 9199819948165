// @ts-nocheck

import React, { useRef, useState } from "react";

import BussinessLogo from "../../assets/image/business/1.png";
import IconButton from "../../components/Buttons/IconButton";
import Input from "../../components/Form/Input";
import DefaultSelect from "../../components/Form/DefaultSelect";
import { getFileUrl } from "../../utils/utils";
import { toast } from "react-toastify";
import { apiMultipart } from "../../services/api";

const SettingGeneral = ({ configs, onSave, onBack = () => { } }) => {

    const fileRef = useRef(null);

    const [logo, setLogo] = useState(configs?.logo ?? null);
    const [platformName, setPlatformName] = useState(configs?.platformName ?? "Hulk Cars");
    const [theme, setTheme] = useState(configs?.theme ?? "Light");

    const handleChangeLogo = () => {
        if (fileRef) {
            fileRef.current.click();
        }
    }

    const handleFileUpload = async (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData();
            formData.append(`file0`, e.target.files[0]);
            formData.append('kind', "logo");

            const res = await apiMultipart.post('/upload', formData);
            if (res?.status === 200) {
                setLogo(res.data.result[0]);
            } else {
                toast.error("File upload failed!");
            }
        }
    }

    const handleDeleteLogo = () => {
        setLogo(null);
    }

    const handleSave = () => {
        onSave({ logo, platformName, theme });
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center pb-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        General Information
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                        Adjust your settings
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col items-start gap-5 bg-white rounded-[8px] p-5 md:p-0 md:pt-5 mt-5 md:mt-0">
                <p className="text-gray6">Business Logo</p>
                <div className="flex items-center gap-6">
                    <img src={logo ? getFileUrl(logo.path) : BussinessLogo} className="w-[100px] h-[100px] rounded-full" alt="logo" />
                    <div className="flex gap-4 flex-col md:flex-row">
                        <input type="file" className="hidden" ref={fileRef} onChange={handleFileUpload} />
                        <IconButton text="Change Logo" TxSize="text-[14px]" py="py-[10px]" className="w-[160px]" onClick={handleChangeLogo} />
                        <button className="text-[14px] text-[#FF5C5C] font-[500] hover:text-white hover:bg-[#FF5C5C] border border-[#FF303033] p-[10px] w-[160px] rounded-[6px]"
                            onClick={handleDeleteLogo}
                        >
                            Delete Logo
                        </button>
                    </div>
                </div>
                <div className="w-full max-w-[550px]">
                    <Input label="Platform name" value={platformName} setValue={setPlatformName} />
                </div>
                <div className="w-full max-w-[550px]">
                    <DefaultSelect
                        label="Theme"
                        options={["Light", "Dark"]}
                        value={theme}
                        setValue={setTheme}
                    />
                </div>
                <IconButton text="Save Change" TxSize="text-[14px]" py="py-[10px]" className="w-full md:w-[160px]" onClick={handleSave} />
                <IconButton text="Back" TxSize="text-[14px]" py="py-[10px]" className="w-full md:hidden" onClick={onBack} />
            </div>
        </div>
    );
};

export default SettingGeneral;