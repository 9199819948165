// @ts-nocheck

import React, { useEffect, useState } from "react";

import StarFullIcon from "../../assets/icons/star-full.svg";
import StarHoleIcon from "../../assets/icons/star-hole.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import Pagination from "../../components/Pagination";
import { deleteNotification, favorNotification, getNotifications } from "../../services/axios";
import { showConfirmBox } from "../../utils/utils";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);


const Notifications = () => {

    const [activeTab, setActiveTab] = useState(0);
    const [dataList, setDataList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [archiveCount, setArchiveCount] = useState(0);
    const [favorCount, setFavorCount] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        loadPage();
    }, [activeTab]);

    const loadPage = async (page = 1) => {
        const res = await getNotifications({
            page, pageSize, type: activeTab
        });
        if (res.status === 200) {
            setDataList(res.data.items);
            setTotalCount(res.data.totalCount);
            setArchiveCount(res.data.archiveCount);
            setFavorCount(res.data.favorCount);
        }
        setCurPage(page);
    }

    const handleFavor = async (item) => {
        const res = await favorNotification(item._id);
        if (res.status === 200) {
            loadPage(curPage);
        }
    }

    const handleRemove = async (item) => {
        showConfirmBox("Warning", "Are you sure to delete?",
            async () => {
                const res = await deleteNotification(item._id);
                if (res.status === 200) {
                    toast.success("Successfully deleted!");
                    loadPage(curPage);
                } else {
                    toast.error(res.data.message);
                }
            });
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Notifications
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[16px]">
                        <p className="text-black text-[18px] font-[600]">All Notification</p>
                    </div>
                    <div className="w-full flex justify-between items-center columns-3 text-[14px]">
                        <div className={`w-full border-b py-3 cursor-pointer flex items-center gap-2 ${activeTab === 0 ? 'text-black border-[#48B825]' : 'text-gray6 border-border2'}`} onClick={() => setActiveTab(0)}>
                            <span>All</span>
                            <div className={`text-[12px] p-[2px_8px] rounded-[14px] ${activeTab === 0 ? 'text-white bg-[#FF5C5C]' : 'text-gray6 bg-[#F3F3F3]'} `}>{totalCount}</div>
                        </div>
                        <div className={`w-full border-b py-3 cursor-pointer flex items-center gap-2 ${activeTab === 1 ? 'text-black border-[#48B825]' : 'text-gray6 border-border2'}`} onClick={() => setActiveTab(1)}>
                            Archive
                            <div className={`text-[12px] p-[2px_8px] rounded-[14px] ${activeTab === 1 ? 'text-white bg-[#FF5C5C]' : 'text-gray6 bg-[#F3F3F3]'} `}>{archiveCount}</div>
                        </div>
                        <div className={`w-full border-b py-3 cursor-pointer flex items-center gap-2 ${activeTab === 2 ? 'text-black border-[#48B825]' : 'text-gray6 border-border2'}`} onClick={() => setActiveTab(2)}>
                            Favourite
                            <div className={`text-[12px] p-[2px_8px] rounded-[14px] ${activeTab === 2 ? 'text-white bg-[#FF5C5C]' : 'text-gray6 bg-[#F3F3F3]'} `}>{favorCount}</div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full overflow-x-auto">
                        {dataList.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[50%] min-w-[250px]">
                                    <p className="text-[12px] font-[500] text-black flex items-center gap-2">
                                        <img src={item.isFavor ? StarFullIcon : StarHoleIcon} alt="star" className="cursor-pointer"
                                            onClick={() => handleFavor(item)}
                                        />
                                        {item.content}
                                    </p>
                                </div>
                                <div className="w-[30%] min-w-[150px]">
                                    <p className="text-[12px] font-[500] text-gray6">{dayjs(item.createdAt).fromNow()}</p>
                                </div>
                                <div className="w-[5%] min-w-[50px] flex justify-end">
                                    <div className="w-[32px] h-[32px] rounded-full bg-[#F3F3F3] flex justify-center items-center cursor-pointer"
                                        onClick={() => handleRemove(item)}
                                    >
                                        <img src={TrashIcon} alt="trash" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <Pagination
                        totalCount={activeTab === 0 ? totalCount : activeTab === 1 ? archiveCount : favorCount}
                        onGoToPage={(page) => loadPage(page)}
                        pageSize={pageSize}
                    />
                </div>
            </div>
        </div>
    );
};

export default Notifications;